import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import LayoutWrapper from "../LayoutWrapper";
import { RecurrenceIcon } from "src/imagepath/imagepath";
import EventConfigModal from "./EventConfigModal";

export class AddEvent extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("AddEvent");
    this.state = {
      data: [],
      event: [{ school_id: "", comment: "" }],
      classes: [],
      fields: [
        {
          type: "select",
          name: "school_id",
          label: "School",
          placeholder: "Select School",
          options: [],
          onChange: (data) => {
            this.handleSchoolChange(data?.[0]?.school_id);
          },
          required: true,
        },
        // {
        //   type: "select",
        //   name: "school_coordinator",
        //   label: "School Coordinator",
        //   placeholder: "Select",
        //   options: [],
        //   required: true,
        //   multi: true,
        // },
        {
          type: "date",
          name: "event_date",
          label: "Date",
          defaultValue: new Date(),
          disabledDates: (date) => {
            return date.getTime() < new Date().toDate().date().getTime();
          },
          onChange: (data) => {
            this.handleEventDateChange(data);
          },
          required: true,
        },
        {
          type: "time",
          name: "event_time",
          label: "Time",
          // fieldclass: "col-4",
          onChange: (e) => {
            this.handleTimeChange(e);
          },
          required: true,
        },
        {
          type: "select",
          name: "class_id",
          label: "Class",
          options: [],
          required: true,
          onChange: (data) => {
            this.getSectionsByClassId(data?.[0]?.class_id);
            this.getTopics(data?.[0]?.class_id);
          },
        },
        {
          type: "select",
          name: "section_id",
          label: "Section",
          options: [],
          onChange: (data) => {
            this.getSectionsByClassId(data?.[0]?.class_id);
          },
          required: true,
        },
        {
          type: "select",
          name: "topic",
          label: "Topic",
          options: [],
          fieldclass: "col-5 pr-5",
          required: true,
        },
        // {
        //   type: "select",
        //   name: "event_status",
        //   label: "Status",
        //   options: [],
        //   required: true,
        // },
        {
          type: "textarea",
          name: "comment",
          label: "Comments",
          fieldclass: "col-9",
        },
      ],
      recurrenceInfo: null,
      loader: false,
    };
    this.form = React.createRef();
  }

  componentDidMount() {
    // this.fetchList();
    // localStorage.setItem("class_id", this.props.urlparams.class_id);
    console.log(this.props.urlparams);
    this.getSchoolNames();
  }
  handleEventDateChange = (data) => {
    this.setState({
      recurrenceInfo: {
        ...this.state.recurrenceInfo,
        start_occurance_date: data,
      },
    });
  };
  handleTimeChange = (e) => {
    this.setState({
      recurrenceInfo: {
        ...this.state.recurrenceInfo,
        event_time: e.target.value,
      },
    });
  };
  getSchoolNames = async () => {
    try {
      const { data } =
        localStorage.getItem("currentAdminRole") == "SCHOOL_COORDINATOR"
          ? await this.props.api.apiGetAllSchoolList()
          : await this.props.api.apiGetAllSchools({
              limit: 50,
              page: 1,
              order_by: "updatedAt",
              order_direction: "DESC",
              status:"ACTIVE"
            });

      let field = this.state.fields.find((o) => o.name === "school_id");
      let opt =
        localStorage.getItem("currentAdminRole") == "SCHOOL_COORDINATOR"
          ? data
          : data?.[0].records;
      field.options = opt?.options("school_id", "school_name");
      if (localStorage.getItem("currentAdminRole") == "SCHOOL_COORDINATOR") {
        field.defaultValue = localStorage.getItem("school_id");
        field.value = localStorage.getItem("school_id");
        field.disabled = true;
      }
      this.setState({
        ...this.state.fields,
      });
    } catch (error) {
      console.log({ error: error });
    }
  };

  handleSchoolChange(school_id) {
    this.getClassesBySchool(school_id);
  }
  getClassesBySchool(school_id) {
    this.props.api
      .apiGetClassById({ school_id: school_id })
      .then(({ data }) => {
        let field = this.state.fields.find((o) => o.name === "class_id");
        field.options = data?.[0].records?.options(
          "class_id",
          "curriculum_name"
        );
        this.setState({
          fields: this.state.fields,
          classes: data?.[0].records,
        });
      })
      .catch((error) => {});
  }

  getSectionsByClassId(classId) {
    let field = this.state.fields.find((o) => o.name === "section_id");
    let classes = this.state.classes.find((r) => r.class_id === classId);
    field.options = classes.section?.options("section_id", "name");
    this.setState({
      ...this.state.fields,
    });
  }

  getTopics(class_id) {
    let payload = {
      class_id: class_id,
    };
    this.setState({
      loader: true,
    });
    this.props.api
      .apiGetAccessedSessions(payload)
      .then(({ data }) => {
        // this.setState({
        //   sessionsData: res.data[0].records,
        //   loader: false,
        // });
        let field = this.state.fields.find((o) => o.name === "topic");
        field.options = data?.options("session_id", "session_name");
        // field.options = data?.find("session_id", "session_name");
        this.setState({
          fields: this.state.fields,
        },()=>{
          this.setState({
            loader: false,
          });
        });
      })
      .catch(() => {
        this.setState({ loader: false });
      });
  }

  async submitForm(e, data) {
    e.preventDefault();
    let parsedPayload = parseInput(data) || [];
    this.setState({
      loader: true,
    });
    let recurrent = {
      ...this.state.recurrenceInfo,
      recurrent_pattern: "Weekly",
      start_occurance_date: parsedPayload?.event_date,
      is_recurrent: this.state.recurrenceInfo?.selected_days?.length > 0,
    };

    if (recurrent.is_recurrent) {
      parsedPayload = {
        ...parsedPayload,
        ...recurrent,
      };
    } else {
      parsedPayload.is_recurrent = false;
    }
    parsedPayload.topic === "" && delete parsedPayload.topic;
    parsedPayload.comment === "" && delete parsedPayload.comment;
    let events = [{
      ...(parsedPayload?.comment?.length > 0) ? {
        ...parsedPayload
      } : {
        ...Object.except(parsedPayload, "comment")
      }
    }];
  if (localStorage.getItem("currentAdminRole") == "SCHOOL_COORDINATOR") {
    parsedPayload.school_id = localStorage.getItem("school_id");
  }
    try {
      const { data } =
        localStorage.getItem("currentAdminRole") == "SCHOOL_COORDINATOR"
          ? await this.props.api.apiCreateSchoolEvent(parsedPayload)
          : await this.props.api.apiCreateEventSuper(events);
      this.props.navigate("/schedulemanagement");
      toast.success("Event added successfully");
      // this.setState({
      //   loader: false,
      // });
    } catch (error) {
      this.setState({
        loader: false,
      });
    }
  }
  handleEventRecurrence(e, data) {
    e.preventDefault();
    let parsedPayload = parseInput(data) || [];
    if (parsedPayload?.selected_days?.length > 0) {
      let field = this.state.fields.find((o) => o.name === "topic");
      field.required = false;
      field.disabled = true;
    }
    this.setState(
      {
        recurrenceInfo: {
          ...parsedPayload,
          selected_days: parsedPayload?.selected_days?.splitBy(","),
        },
        fields: this.state.fields,
      },
      () => {
        document.querySelector("#add_recurrence_form #bsmodalclose").click();
      }
    );
  }

  render() {
    let { data, loader } = this.state;
    return (
      <LayoutWrapper
        title="Schedule Management"
        back="Schedule Management / Add Event"
        backlink="/schedulemanagement"
      >
        {loader && (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        )}
        <div className="row m-auto">
          <AppForm
            ref={this.form}
            className="flex flex-col gap-4 bg-white p-4"
            onSubmit={(...args) => this.submitForm(...args)}
          >
            <div className="row align-items-center">
              {this.state.fields?.map((field, i) => (
                <>
                  <div
                    className={
                      field.fieldclass
                        ? field.fieldclass
                        : "col-12 col-md-6 col-lg-3"
                    }
                  >
                    <AppInput
                      // type={field.type}
                      {...field}
                      name={field.name}
                      // label={field.label}
                      // defaultValue={field.name}
                    ></AppInput>{" "}
                  </div>
                  {i === 2 && (
                    <div className="col-12 col-md-6 col-lg-2 mt-4">
                      <div>
                        <a
                          className="dropdown-item"
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#add_recurrence_form"
                          // onClick={() => callback(record)}
                        >
                          <img src={RecurrenceIcon} className=""/> Event Recurrence
                        </a>
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
            <div className="row no-gutters">
              <Link
                to="/schedulemanagement"
                className="btn btn_primary col col-md-1 ml-auto grey"
              >
                Cancel
              </Link>
              <button
                className="btn add-btn col col-md-1 ml-auto ms-2 me-2"
                type="submit"
              >
                Submit
              </button>
            </div>
          </AppForm>
        </div>
        <EventConfigModal
          id="add_recurrence_form"
          title="Event Recurrence"
          recurrenceInfo={this.state.recurrenceInfo}
          onSubmit={(...args) => this.handleEventRecurrence(...args)}
        />
      </LayoutWrapper>
    );
  }
}

export default connect(AddEvent);
