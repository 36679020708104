import React, { Component } from "react";
import EnquiryModal from "./EnquiryModal";
import LayoutWrapper from "./LayoutWrapper";
import { Link } from "react-router-dom";
import moment from "moment";

export class Enquires extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("Enquires");
    this.state = {
      data: [],
      rowcount: 0,
      search: {
        limit: 50,
        page: 1,
        order_by: "updatedAt",
        order_direction: "DESC",
      },
      editData: {},
      viewMode: false,
      editMode: false,
      popupfields: {},
      popupdata: {},
      loader: false,
    };
    this.columns = [
      {
        dataIndex: "name",
        title: "Name",
      },
      {
        dataIndex: "phone_number",
        title: "Mobile No.",
      },
      {
        dataIndex: "email",
        title: "Email ID",
      },
      {
        dataIndex: "leads_remarks",
        title: "Enquiry Remarks",
        render: (text, record) => {
          return <div title={text} className="remarks">{text}</div>;
        },
      },
      {
        dataIndex: "admin_remarks",
        title: "Admin Remarks",
        render: (text, record) => {
          return <div title={text} className="remarks">{text}</div>;
        },
      },
      {
        dataIndex: "status",
        title: "Status",
        render: (text) => {
          return text.toTitleCase();
        },
      },
      {
        dataIndex: "createdAt",
        title: "Created On",
        render: (text) => {
          return <>{moment(text)?.format("DD-MM-YYYY") || ""}</>;
        },
      },
      {
        dataIndex: "updatedAt",
        title: "Updated On",
        render: (text) => {
          return <>{moment(text)?.format("DD-MM-YYYY") || ""}</>;
        },
      },
    ];
    this.modalFields = [
      {
        type: "text",
        name: "name",
        label: "Name",
        disabled: true,
      },
      {
        type: "email",
        name: "email",
        label: "Email ID",
        disabled: true,
      },
      {
        type: "text",
        name: "phone_number",
        label: "Mobile No.",
        pattern: "[0-9]{10}",
        onInvalid: (e) =>
          e.target.setError(
            "ex. 91-9XXXXXXXXX 10 digit phone number with country code."
          ),
        onInput: (e) => e.target.setMessage(),
        disabled: true,
      },
      {
        type: "select",
        name: "status",
        label: "Status",
        options: _enum.ENQUIRY_STATUS,
        required: true,
      },
      {
        type: "textarea",
        name: "admin_remarks",
        label: "Admin Remarks",
      },
    ];
    this.enquireModalRef = React.createRef();
  }

  componentDidMount() {
    this.fetchList();
  }

  addModalFields() {
    let fields = (this.modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    this.setState({
      popupfields: fields,
      popupdata: formdata,
    });
  }

  fetchList() {
    this.setState({
      loader: true,
    });
    this.props.api
      .apiGetAllLeads(this.state.search)
      .then(({ data, total }) => {
        console.log({ data });
        this.setState({
          data: [...data],
          rowcount: total,
        });
      })
      .then(() => {
        this.setState({
          loader: false,
        });
      });
  }

  onNext() {}

  onPrev() {}

  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      () => this.fetchList()
    );
  }

  deleteAction(value) {
    let payload = {
      lead_id: value.lead_id,
    };

    confirm(`Are you sure want to delete this Enquiry ?`).promise.then(() => {
      this.api.apiDeleteLead(payload).then(() => {
        this.fetchList();
      });
    });
  }

  editAction(v) {
    console.log("edit: ", v);
    this.openEnquireModal();

    let popdata = Object.except(v, [
      "lead_id",
      "leads_remarks",
      "createdAt",
      "updatedAt",
    ]);
    popdata.admin_remarks = v.admin_remarks || "";
    console.log({ popdata });
    this.setState({
      popupdata: popdata,
      editMode: true,
      viewMode: false,
      editData: v,
    });
    // update status
    let enquiryStatus = this.modalFields.find((o) => o.name == "status");
    enquiryStatus.options = Object.assign(
      enquiryStatus.options,
      _enum.ENQUIRY_STATUS[v.status]
    );
    // let leadsRemarks = this.modalFields.find((o) => o.name == "leads_remarks");
    // leadsRemarks.label = "Admin Remarks";
  }

  viewAction(v) {
    // console.log("view: ", v);
    this.openEnquireModal();
    this.setState({
      popupdata: Object.except(v, ["createdAt", "updatedAt"]),
      editMode: false,
      viewMode: true,
      editData: v,
    });
  }

  onSearch(data) {
    // console.log("onSearch data: ", data);
    this.setState({ search: data }, () => {
      this.fetchList();
    });
  }

  openEnquireModal() {
    this.enquireModalRef.current.click();
  }

  closeModal() {
    document.querySelector("#enquire_modal #bsmodalclose").click();
  }

  handleSubmit(event, data, form) {
    event.preventDefault();
    console.log("formData", data, form);
    // delete data.sections;
    this.setState({
      loader: true,
    });

    const resolveList = () => {
      success(
        `${
          this.state.editMode
            ? "Enquiry Updated successfully"
            : "Enquiry added successfully"
        }`
      );
      form.reset();
      this.closeModal();
      this.fetchList();
    };
    if (this.state.editMode) {
      let payload = {
        // leads_remarks: this.state?.editData?.leads_remarks,
        admin_remarks: data.admin_remarks,
        status: data?.status,
      };
      this.props.api
        .apiUpdateLead(payload, { lead_id: this.state.editData.lead_id })
        .then(resolveList)
        .then(() => {
          this.setState({
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            loader: false,
          });
        });
    } else {
      let payload = {
        ...data,
      };
      //   this.props.api.apiAddLead(payload).then(resolveList).then(() => {
      //     this.setState({
      //       loader: false
      //     })
      //   });
    }
  }

  updateFields(fields) {
    return fields;
  }
  render() {
    let {
      data = [],
      rowcount,
      editData,
      popupdata,
      popupfields,
      editMode,
      viewMode,
      loader,
    } = this.state;
    let { columns, modalFields } = this;
    modalFields = this.updateFields(modalFields);
    return (
      <>
        {loader && (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        )}
        <LayoutWrapper title="Enquires" back="Enquires">
          <div className="row d-none">
            <div className="col"></div>
            <div className="mb-4">
              <Link
                className="btn add-btn"
                ref={this.enquireModalRef}
                data-bs-toggle="modal"
                data-bs-target="#enquire_modal"
                onClick={(...args) => this.addModalFields(...args)}
              >
                <i className="fa fa-plus"></i>
                Add Lead
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {!!data.length && (
                <AppTable
                  data={data}
                  columns={columns}
                  onNext={() => this.onNext()}
                  onPrev={() => this.onPrev()}
                  onChange={(...arg) => this.onPageChange(...arg)}
                  total={rowcount}
                  reorder={true}
                  deleteAction={(v) => this.deleteAction(v)}
                  editAction={(v) => this.editAction(v)}
                  //   viewAction={(v) => this.viewAction(v)}
                  viewable={false}
                  targetType="tap"
                ></AppTable>
              )}
              {data.length == 0 && <div className="empty_layout"></div>}
            </div>
          </div>
          <EnquiryModal
            id="enquire_modal"
            title={`${editMode ? "Edit Enquiry Details" : "Add Enquiry"}`}
            submitText="Save"
            editdata={editData}
            data={popupdata}
            fields={popupfields}
            disableallfields={viewMode ? true : false}
            onSubmit={(...args) => this.handleSubmit(...args)}
            onClose={() =>
              this.setState({
                popupdata: {},
                editData: {},
                editMode: false,
                viewMode: false,
              })
            }
          />
        </LayoutWrapper>
      </>
    );
  }
}

export default connect(Enquires);
