/**
 * Signin Firebase
 */

import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

const Loginpage = (props) => {
  const schema2 = yup.object({
    // email: yup
    //   .string()
    //   .matches(window?.constant?.emailrgx, "Email is required")
    //   .required("Email is required")
    //   .trim(),
    password: yup.string().required("Password is required").trim(),
  });

  const navigate=useNavigate()

  const [eye, seteye] = useState(true);
  const [loginToggle, setLoginToggle] = useState(true);
  const [otpToggle, setOtpToggle] = useState(true);
  const [passwordToggle, setPasswordToggle] = useState(false);
  const [loader, setLoader] = useState(false);
  const {
    control: control2,
    handleSubmit: handlePasswordSubmit,
    setError: setError2,
    clearErrors,
    formState: { errors2 },
  } = useForm({
    resolver: yupResolver(schema2),
  });

  const onPasswordSubmit = (data) => {
    const payload = {
      type: "TABLE",
      user_id: data?.user_id,
      password: data?.password,
    };
    console.log("onPasswordSubmit", payload);
   
    // props.setstore("logged", {});
    // props.setstore("auth", true);
    // props.setstore("user", {});
    // localStorage.setItem("currentAdminRole", "TABLE_LOGIN");
    // localStorage.setItem("is_password_updated", undefined)

    props.api
    .apiStudentAndSchoolCoordinatorLogin(payload)
    .then((res) => {
      console.log({ res });
      props.setstore("logged", res.logged);
      props.setstore("auth", res.auth);
      props.setstore("user", res.user);

      clearErrors("password");
      localStorage.setItem("school_admin_id", res?.user?.school_id)

      if (res?.user?.temp_pass) {
        navigate('/open/resetpassword')
      }
      setLoader(false);
    })
    .catch(() => {
      setError2("password", {
        message: "login failed",
      });
      setLoader(false);
    });
  };
  const onEyeClick = () => {
    seteye(!eye);
  };

  return (
    <>
      {loader && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
      <Helmet>
        <title>Login - CognoSpace</title>
        <meta name="description" content="Login page" />
      </Helmet>
      <div className="account-content">
        {/* <Link to="/applyjob/joblist" className="btn btn-primary apply-btn">
					Apply Job
				</Link> */}
        <div className="container">
          {/* /Account Logo */}
          <div className="account-box">
            <div className="account-wrapper">
              {/* Account Logo */}
              <div className="py-3 d-flex justify-content-center">
                <Link to={"/open/login"}>
                  <img
                    src={imagepaths.CognoSpaceLogoDark}
                    alt="Logo"
                    width="155px"
                  />
                </Link>
              </div>
              <h3 className="account-title">Table Login</h3>
              <p className="account-subtitle">Access to our dashboard</p>

              {/* Account Form */}
              <form
                className="email_form"
                key={2}
                onSubmit={handlePasswordSubmit(onPasswordSubmit)}
              >
                <>
                  <div className="form-group">
                    <label>Table ID</label>
                    <Controller
                      name="user_id"
                      control={control2}
                      render={({ field: { value, onChange } }) => (
                        <input
                          className={`form-control  ${
                            errors2?.required ? "error-input" : ""
                          }`}
                          type="text"
                          value={value}
                          onChange={onChange}
                          autoComplete="false"
                          placeholder="Enter Table ID"
                          required={true}
                        />
                      )}
                    />
                    <small>{errors2?.required?.message}</small>
                  </div>

                  <div className="form-group">
                    <div className="row">
                      <div className="col">
                        <label>Password</label>
                      </div>
                    </div>
                    <Controller
                      name="password"
                      control={control2}
                      render={({ field: { value, onChange } }) => (
                        <div className="pass-group">
                          <input
                            type={eye ? "password" : "text"}
                            className={`form-control  ${
                              errors2?.password ? "error-input" : ""
                            }`}
                            value={value}
                            onChange={onChange}
                            autoComplete="false"
                            placeholder="Enter your password here"
                            required={true}
                          />
                          <span
                            onClick={onEyeClick}
                            className={`fa toggle-password" ${
                              eye ? "fa-eye-slash" : "fa-eye"
                            }`}
                          />
                        </div>
                      )}
                    />
                    <p className="mt-3">
                      <a
                        className="text-dark text-decoration-underline"
                        href="https://cognospace.in/privacy-policy.html"
                        target="_blank"
                      >
                        Privacy Policy
                      </a>{" "}
                    </p>
                    <small>{errors2?.password?.message}</small>
                    {/* <div className="col-auto d-flex justify-content-between">
                      <Link
                        className="text-muted ms-auto"
                        to="/open/forgotpassword"
                      >
                        Forgot password?
                      </Link>
                    </div> */}
                  </div>

                  <div className="form-group text-center">
                    <button
                      className="btn btn-primary account-btn"
                      type="submit"
                    >
                      Login
                    </button>
                  </div>
                </>
              </form>
              {/* /Account Form */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(Loginpage);
