export const menu = [
    {
        label: 'My Profile',
        icon: 'pi pi-fw pi-user',
        url:"/profile"
    },
    {
        label:'Logout',
        icon:'pi pi-fw pi-sign-out',
        url:"/logout"
    }
]

export default menu;