import React, { Component } from "react";
import _enum from "src/mixins/enum";

export class AttendanceCurrentSearch extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();

    this.state = {
      loader: false,
      classes: [],
      fieldsForCordCurrent: [
        {
          type: "select",
          name: "event_id",
          label: "Event",
          placeholder: "Search here",
          labelClass: "text-bold",
          options: [],
          onChange: (data) => {
            this.setState(
              {
                event_data: data,
              },
              () => {
                this.getClassesBySchool(localStorage.getItem("school_id"));
              }
            );
          },
        },
        {
          type: "select",
          name: "class_id",
          label: "Class",
          placeholder: "Search here",
          labelClass: "text-bold",
          disabled: true,
          options: [],
          onChange: (data) => {
            this.getSectionsByClassId(data[0]?.class_id);
          },
        },
        {
          type: "select",
          name: "section_id",
          label: "Section",
          placeholder: "Search here",
          labelClass: "text-bold",
          options: [],
          disabled: true,
          onChange: (data) => {
            this.onSearch();
          },
        },
        {
          type: "select",
          multi: true,
          name: "profile_id",
          label: "Student Name",
          placeholder: "Search here",
          labelClass: "text-bold",
          options: [],
        },
      ],
      fields: [],
      classes: [],
    };
  }

  trimObjectValues(obj) {
    const trimmedObj = {};
    for (const key in obj) {
      if (typeof obj[key] === "string") {
        trimmedObj[key] = obj[key].trim();
      } else {
        trimmedObj[key] = obj[key];
      }
    }
    return trimmedObj;
  }

  onSearch() {
    let data = this.refs.form.form.current.getData();
    // console.log("onSearch formData", data);

    data = this.trimObjectValues(data);
    data = Object.filter(data, (val) => val);
    data.event_data = this.state.event_data?.[0];
    if (data.event_data) {
      data.section_id = data.event_data?.section_id;
      data.event_id = data?.event_id || data.event_data?.event_id;
    }
    this.props.onSearch && this.props.onSearch(data);
  }

  componentDidMount() {
    this.setState(
      {
        fields: this.state.fieldsForCordCurrent,
      },
      () => {
        this.getTodaysEventsBySchool(localStorage.getItem("school_id"));
      }
    );
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.student_data !== this.props.student_data) {
      // this.getTaskCounts();
      if (this.props.student_data?.length > 0) {
        let field = this.state.fields.find((o) => o.name === "profile_id");
        if (field) {
          field.options = this.props.student_data?.options(
            "profile_id",
            "student_name"
          );
          this.setState({
            fields: this.state.fields,
          });
        }
      }
    }
  }

  getTodaysEventsBySchool(school_id) {
    let { date, event } = this.props.urlparams;
    let payload = {};
    if (date !== undefined) {
      payload = { event_date: date };
    }
    this.props.api
      .apiGetTodaysEventsBySchoolId({ school_id: school_id }, payload)
      .then(({ data }) => {
        let field = this.state.fields.find((o) => o.name === "event_id");
        let opt = data?.map((item) => {
          return {
            ...item,
            event_id: item.event_id,
            session_name: item?.session?.session_name || "",
          };
        });
        field.options = opt?.options("event_id", "session_name");
        if (date != undefined) {
          field.defaultValue = event;
          field.disabled = true;
        }
        // console.log(field);
        this.setState({
          fields: this.state.fields,
        });
      })
      .catch((error) => {});
  }

  getEventDetailsById(event_id) {
    if (event_id) {
      this.props.api
        .apiGetSingleEvent({ event_id: event_id })
        .then(({ data }) => {
          let field = this.state.fields.find((o) => o.name === "event_id");
          let opt = [data];
          field.options = opt?.options("event_id", "session_name");
          field.defaultValue = event_id;
          field.disabled = true;
          this.setState({
            fields: this.state.fields,
          });
        })
        .catch((error) => {});
    }
  }

  getClassesBySchool(school_id) {
    this.props.api
      .apiGetClassById({ school_id: school_id })
      .then(({ data }) => {
        let field = this.state.fields.find((o) => o.name === "class_id");
        field.options = data?.[0].records?.options(
          "class_id",
          "curriculum_grade"
        );
        field.defaultValue = this.state?.event_data?.[0]?.class_id;
        this.setState(
          {
            fields: this.state.fields,
            classes: data?.[0].records,
          },
          () => {
            // this.getSectionsByClassId(this.state?.event_data?.[0]?.class_id)
          }
        );
      })
      .catch((error) => {});
  }

  getSectionsByClassId(classId) {
    if (classId) {
      let field = this.state.fields.find((o) => o.name === "section_id");
      let classes = this.state.classes.find((r) => r.class_id === classId);
      field.options = classes.section?.options("section_id", "name");
      field.defaultValue = this.state?.event_data?.[0]?.section_id;
      this.setState({
        ...this.state.fields,
        sections: classes.section,
      });
    }
  }
  getSchoolNames() {
    let payload = {
      status: "ACTIVE",
    };
    this.props.api
      .apiGetAllSchools(payload)
      .then(({ data, total }) => {
        let options = data[0].records?.options("school_id", "school_name");
        let schoolField = this.state.fields.find((o) => o.name == "school_id");
        schoolField.options = options;
        this.setState({
          fields: this.state.fields,
        });
      })
      .catch((error) => {});
  }

  clearTextData = () => {
    this.setState({
      loader: true,
    });
    location.reload();
  };
  render() {
    let { fields, loader } = this.state;

    return (
      <div className="card bg-white p-3">
        {loader && (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        )}
        <AppForm className="row filter-row" ref="form">
          {fields.map((field, i) => (
            <div
              className={
                ["profile_id", "event_id"].includes(field.name) &&
                localStorage.getItem("currentAdminRole") == "SCHOOL_COORDINATOR"
                  ? "col-sm-6 col-md-3"
                  : "col-sm-6 col-md-2"
              }
              key={i}
            >
              <AppInput {...field}></AppInput>
            </div>
          ))}
          <div className="col-sm-6 col-md-3 col-lg-2 position-relative">
            <button
              className="btn btn-success btn-block w-100 mt-md-3"
              onClick={() => this.onSearch()}
            >
              Search
            </button>
            <span
              className="clear-all schedule"
              onClick={() => this.clearTextData()}
            >
              Clear Filter
            </span>
          </div>
        </AppForm>
      </div>
    );
  }
}

export default connect(AttendanceCurrentSearch);
