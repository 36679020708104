import { Component } from "react";
import LayoutWrapper from "../../../LayoutWrapper";
import "../module.scss";

const AssessmentsDetailsPage=(props)=>  {

    return (
      <div>
        <ul className="preview_assessment_box">
          {props?.data?.assessment_answers?.map((qn,i) => (
            <li>
              <div className={Object.className({
              "card": true,
              "correct": qn.correct_answer && props?.isPreview,
              "wrong": !qn.correct_answer && props?.isPreview,
            })}>
                <div className="card-header">
                  <div className="me-3">Q{i+1}.</div>
                  <div className="question_box">
                    <p className="question">
                     {qn?.q_label}
                    </p>
                    {props?.isPreview && <>
                      {qn.correct_answer?<i className="fa fa-circle-check"></i>
                    :<i class="fa fa-times"></i>}
                    </>}
                  </div>
                </div>
                <div className="answer_box">
                  {props?.isPreview && <div className="user_answer me-5 pe-5">
                    <p className="text_label">Your Answer</p>
                    <p className="answer">{qn.student_answer}</p>
                  </div>}
                  <div className="correct_answer">
                    <p className="text_label">Correct Answer</p>
                    <p className="answer">{qn.value}</p>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }

export default connect(AssessmentsDetailsPage);
