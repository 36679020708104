import { Radio } from "antd";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import _enum from "src/mixins/enum";

export class ScheduleSearch extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("ScheduleSearch");
    this.handleRangeChange = this.handleRangeChange.bind(this);
    this.form = React.createRef();
    this.state = {
      fields: [
        {
          type: "text",
          name: "topic",
          label: "Topic",
          placeholder: "Search by Topic Name",
          labelClass: "text-bold",
        },
        {
          type: "select",
          name: "school_id",
          label: "School",
          labelClass: "text-bold",
          options: [],
          // onChange: (data) => {
          //   this.getCoordinators(data?.[0]?.school_id);
          //   this.onSearch();
          // },
        },
        {
          type: "select",
          name: "created_by",
          label: "Created By",
          labelClass: "text-bold",
          options: [],
        },
        // {
        //   type: "select",
        //   name: "school_coordinator",
        //   label: "School Coordinator",
        //   placeholder: "Select",
        //   labelClass: "text-bold",
        //   options: [],
        //   onChange: (data) => {
        //     this.onSearch();
        //   },
        // },
      ],
    };
  }
  componentDidMount() {
    if(localStorage.getItem("currentAdminRole") === "SUPER_ADMIN"){
      this.getSchoolNames();
    }else if(localStorage.getItem("currentAdminRole") === "SCHOOL_COORDINATOR"){
      this.getCoordinators();
    }
  }

  getSchoolNames = () => {
    this.props.api
      .apiGetAllSchools({
        limit: 50,
        page: 1,
        order_by: "updatedAt",
        order_direction: "DESC",
        status:"ACTIVE"
      })
      .then(({ data, total }) => {
        let field = this.state.fields.find((o) => o.name === "school_id");
        field.options = data?.[0].records?.options("school_id", "school_name");
        this.setState({
          ...this.state.fields,
        });
      })
      .then(() => {})
      .catch(() => {});
  };

  getCoordinators() {
    let school_id = localStorage.getItem("school_id") || "";
    this.props.onSearch && this.props.onSearch({ school_id: school_id });
    let payload = {};
    this.props.api
      .apiGetSchoolCoordinatorsById(payload, { school_id: school_id })
      .then(({ data }) => {
        let field = this.state.fields.find(
          (o) => o.name === "created_by"
        );
        field.options = [ {key: "admin", label: "Admin"} , ...data?.[0].records?.options("profile_id", "name")];
        this.setState({
          ...this.state.fields,
        });
      })
      .catch((error) => {});
  }

  handleRangeChange(e) {
    this.props.onSearch &&
      this.props.onSearch({ event_status: e.target.value });
  }

  trimObjectValues(obj) {
    const trimmedObj = {};
    for (const key in obj) {
      if (typeof obj[key] === "string") {
        trimmedObj[key] = obj[key].trim();
      } else {
        trimmedObj[key] = obj[key];
      }
    }
    return trimmedObj;
  }

  onSearch() {
    let data = this.refs.form.form.current.getData();
    // console.log("onSearch formData", data);

    data = this.trimObjectValues(data);
    data = Object.filter(data, (val) => val);
    // console.log("data on search", data);
    this.props.onSearch && this.props.onSearch(data);
  }

  clearTextData = () => {
    this.props.onSearch && this.props.onSearch({});
    this.refs.form.form.current.reset();
  };

//   handleInputChange = (i, event) => {
//     const newFields = [...this.state.fields];
// console.log({i,event})
//     // if (newFields[i].name != "status") newFields[i].value = event.target.value;
//     // this.setState({ fields: newFields });
//   };

  render() {
    let { fields } = this.state;
    let is_coordinator =
      localStorage.getItem("currentAdminRole") == "SCHOOL_COORDINATOR";

    return (
      <div className={Object.className({
        "card bg-white p-3": true,
        // "d-none": is_coordinator
      })}>
        <AppForm
          className="row filter-row align-items-center justify-content-lg-end"
          ref="form"
        >
          {/* <div className="col-sm-6 col-md-5"></div> */}
          {fields
            .filter((v) =>
              is_coordinator ? v.name != "school_id" : v.name != "created_by"
            )
            .map((field, i) => (
              <div className="col-sm-6 col-md-4" key={i}>
                <AppInput
                  {...field}
                  // onChange={(event) => {
                  //   this.props.onChange && this.props.onChange(event);
                  //   this.onSearch();
                  // }}
                ></AppInput>
              </div>
            ))}
          <div className="col-sm-6 col-md-3 col-lg-2 position-relative">
            <button
              className="btn btn-success btn-block w-100 mt-md-3"
              onClick={() => this.onSearch()}
            >
              Search
            </button>
            <span
              className="clear-all schedule"
              onClick={() => this.clearTextData()}
            >
              Clear Filter
            </span>
          </div>
          <div className="col-sm-6 col-md-3 col-lg-2">
            <Link
              className="btn add-btn mt-md-3"
              onClick={(...args) => this.props.addModalFields(...args)}
            >
              <i className="fa fa-plus"></i>
              Add Event
            </Link>
          </div>
          <div className="col-12 mt-4">
            <div className="mb-1">Show Agenda</div>
            <Radio.Group
              defaultValue=""
              buttonStyle="solid"
              className="calendar-filter"
              onChange={this.handleRangeChange}
            >
              <Radio.Button value="">All</Radio.Button>
              <Radio.Button value="OPEN">Scheduled</Radio.Button>
              <Radio.Button value="COMPLETED">Completed</Radio.Button>
              <Radio.Button value="CANCELLED">Cancelled</Radio.Button>
            </Radio.Group>
          </div>
        </AppForm>
      </div>
    );
  }
}

export default connect(ScheduleSearch);
