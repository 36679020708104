export default {
  superAdmin: [
    {
      path: "/dashboard",
      iconStyle: imagepaths.Dashboard,
      iconActive: imagepaths.DashboardActive,
      label: "Dashboard",
    },
    {
      path: "/schoolmanagement",
      iconStyle: imagepaths.School,
      iconActive: imagepaths.SchoolActive,
      label: "School Management",
    },
    {
      path: "/invoiceform",
      iconStyle: imagepaths.InvoiceIcon,
      iconActive: imagepaths.InvoiceIconActive,
      label: "Invoice Form",
    },
    {
      path: "/masterdata",
      iconStyle: imagepaths.MasterData,
      iconActive: imagepaths.MasterDataActive,
      label: "Master Data",
      childrenIds: ["/submodules", "/deviceconfiguration"],
      children: [
        {
          label: "Sub Modules",
          icon: "",
          path: "/submodules",
        },
        // {
        //   label: "Device Configuration",
        //   icon: "",
        //   path: "/deviceconfiguration",
        // },
      ],
    },
    // {
    //     path: "/classmanagement",
    //     icon: "la la-file-text",
    //     label: "Class Management",
    // },
    {
      path: "/contentmanagement",
      iconStyle: imagepaths.Content,
      iconActive: imagepaths.ContentActive,
      label: "Content Management",
    },
    {
      path: "/employeemanagement",
      iconStyle: imagepaths.Employee,
      iconActive: imagepaths.EmployeeActive,
      label: "Employee Management",
    },
    {
      path: "/schedulemanagement",
      iconStyle: imagepaths.ScheduleIcon,
      iconActive: imagepaths.ScheduleIconActive,
      label: "Schedule Management",
    },
    {
      path: "/taskmanagement",
      iconStyle: imagepaths.InvoiceIcon,
      iconActive: imagepaths.InvoiceIconActive,
      label: "Task Management",
    },
    {
      path: "/attendancemanagement/history",
      iconStyle: imagepaths.attendanceIcon,
      iconActive: imagepaths.attendanceIcon,
      label: "Attendance",
    },
    {
      path: "/enquires",
      iconStyle: imagepaths.EnquiryIcon,
      iconActive: imagepaths.EnquiryIconActive,
      label: "Enquires",
    },
    {
      path: "/gallerymanagement",
      iconStyle: imagepaths.GalleryIcon,
      iconActive: imagepaths.GalleryIconActive,
      label: "Gallery Management",
    },
  ],
  schoolAdmin: [
    {
      path: "/dashboard",
      iconStyle: imagepaths.Dashboard,
      iconActive: imagepaths.DashboardActive,
      label: "Welcome",
    },
    {
      path: "/invoiceform",
      iconStyle: imagepaths.InvoiceIcon,
      iconActive: imagepaths.InvoiceIconActive,
      label: "Invoice Form",
    },
    {
      path: "/schoolmanagement",
      iconStyle: imagepaths.School,
      iconActive: imagepaths.SchoolActive,
      label: "School Management",
    },
  ],
  schoolCoordinator: [
    {
      path: "/dashboard",
      iconStyle: imagepaths.Dashboard,
      iconActive: imagepaths.DashboardActive,
      label: "Home",
    },
    {
      path: "/contentmanagement",
      iconStyle: imagepaths.Content,
      iconActive: imagepaths.ContentActive,
      label: "Content Delivery",
    },
    {
      path: "/schooladmin/classmanagement",
      iconStyle: imagepaths.ClassManagement,
      iconActive: imagepaths.ClassManagementActive,
      label: "Class Management",
    },
    {
      path: "/schedulemanagement",
      iconStyle: imagepaths.ScheduleIcon,
      iconActive: imagepaths.ScheduleIconActive,
      label: "Schedule Management",
    },
    {
      path: "/taskmanagement",
      iconStyle: imagepaths.InvoiceIcon,
      iconActive: imagepaths.InvoiceIconActive,
      label: "Task Management",
    },
    {
      path: "/attendancemanagement",
      iconStyle: imagepaths.attendanceIcon,
      iconActive: imagepaths.attendanceIcon,
      label: "Attendance",
      childrenIds: ["/attendancemanagement/current", "/attendancemanagement/history"],
      children: [
        {
          label: "Today’s Attendance",
          icon: "",
          path: "/attendancemanagement/current",
        },
        {
          label: "History",
          icon: "",
          path: "/attendancemanagement/history",
        },
      ],
    },
  ],
};