export default {
  apiGetAllTasks(data) {
    let payload = {
      ...data,
    };
    return this.request(this.api().getAllTasks, payload)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data[0].records,
          total: response.data[0].pagination[0]?.totalRecords || 0,
        };
      })
      .get();
  },
  apiAddTask(data) {
    // console.log({ apiAddLead: data });
    let payload = {
      ...data,
    };
    return this.request(this.api().createTask, payload).exec().log().get();
  },
  apiUpdateTask(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().updateTask, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiDeleteTask(urldata = mixins.params()) {
    return this.request(this.api().deleteTask)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiGetTaskCount(urldata = mixins.params()) {
    return this.request(this.api().getTaskCount)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
};
