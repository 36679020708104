import { Component } from "react";
import LayoutWrapper from "../LayoutWrapper";
import AttendanceDetailsCurrent from "./AttendanceDetailsCurrent";

export class AttendanceCurrent extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("AttendanceCurrent");
  }

  componentDidMount() {}
  render() {
    let isSuperAdmin =
      localStorage.getItem("currentAdminRole") == "SUPER_ADMIN";
    return (
      <>
            {!isSuperAdmin && <AttendanceDetailsCurrent/>}
      </>
    );
  }
}

export default connect(AttendanceCurrent);
