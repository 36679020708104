import React, { Component } from "react";
export class TableSearch extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      fields: [
        {
          type: "select",
          name: "class_name",
          label: "Class",
          placeholder: "Search here",
          labelClass: "text-bold",
          options: [],
          clearable: true,
          // clearSearchValue: (data) => {
          //   // this.clearClassAndSection();
          //   this.onTableSearch();
          // },
        },
        {
          name: "table_no",
          label: "Table No.",
          type: "text",
          labelClass: "p-0",
          defaultValue: " ",
          onInput:(e)=>this.handleInputChange(1,e)
        },
        {
          name: "user_id",
          label: "User ID",
          type: "text",
          labelClass: "p-0",
          defaultValue: " ",
          searchField: true,
          onInput:(e)=>this.handleInputChange(2,e)
        },
      ],
    };
  }

  trimObjectValues(obj) {
    const trimmedObj = {};
    for (const key in obj) {
      if (typeof obj[key] === "string") {
        trimmedObj[key] = obj[key].trim();
      } else {
        trimmedObj[key] = obj[key];
      }
    }
    return trimmedObj;
  }

  onTableSearch() {
    let data = this.refs.form.form.current.getData();
    data = {
      ...data,
    };
    // console.log("onTableSearch formData", data);
    data = this.trimObjectValues(data);
    data = Object.filter(data, (val) => val);
    // console.log("data on search", data);
    this.props.onTableSearch && this.props.onTableSearch(data);
  }

  clearTextData = () => {
    let classclear = document.getElementById("class_name");
    if (classclear) {
      classclear.click();
    }
    this.setState({
      fields: this.state.fields.map((field) => ({ ...field, value: "" })),
    });
    let data = this.refs.form.form.current.getData();
    data = Object.filter(data, (val) => "");
    this.props.onTableSearch && this.props.onTableSearch(data);
  };

  handleInputChange = (i, event) => {
    const newFields = [...this.state.fields];
    newFields[i].value = event.target.value;
    this.setState({ fields: newFields });
  };

  componentDidMount() {
    if (localStorage.getItem("currentAdminRole") === "SCHOOL_COORDINATOR") {
      this.getClassesBySchool(localStorage.getItem("school_id"));
    }
  }

  getClassesBySchool(school_id) {
    this.props.api
      .apiGetClassById({ school_id: school_id })
      .then(({ data }) => {
        let field = this.state.fields.find((o) => o.name === "class_name");
        field.options = data?.[0].records
          ?.map((item) => {
            return {
              ...item,
              class_name: item?.curriculum_grade,
            };
          })
          .options("class_name");
        this.setState({
          fields: this.state.fields,
          classes: data?.[0].records,
        });
      })
      .catch((error) => {});
  }

  render() {
    let { fields } = this.state;

    return (
      <AppForm className="row filter-row" ref="form">
        {fields.map((field, i) => (
          <div className="col-sm-6 col-md-3" key={i}>
                       <AppInput {...field}></AppInput>
          </div>
        ))}
        <div className="col-sm-6 col-md-3 align-content-center">
          <button
            className="btn btn-success btn-block w-100"
            onClick={() => this.onTableSearch()}
          >
            Search
          </button>
        </div>
        <div className="clear-all">
          <span onClick={() => this.clearTextData()}>Clear Filter</span>
        </div>
      </AppForm>
    );
  }
}

export default connect(TableSearch);
