import React, { Component } from "react";

export class PillarCard extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("PillarCard");
  }
  render() {
    let {
      title,
      percent,
      totalSessions,
      bgImg,
      description,
      completed = false,
    } = this.props;
    return (
      <>
        <div
          className="card pillar_card"
          style={{ backgroundImage: `url('${bgImg}')` }}
        >
          <div className="card-body">
            <h3 className="title">{title}</h3>
            <p className="content">{description}</p>
            {/* {completed && (
              <>
                <hr />
                <div className="progress_circle_container">
                <div className="progress_circle">
                  <p className="m-0">{parseFloat(percent.toFixed(2))}% Completed</p>
                </div>
                {/* <p className="sessions m-0">{totalSessions} Sessions</p> */}
                {/* </div>
              </> 
            )} */}
          </div>
        </div>
      </>
    );
  }
}

export default connect(PillarCard);
