import React, { Component } from "react";
import { Link } from "react-router-dom";
import ClassModal from "../../ClassModal";
import LayoutWrapper from "../../LayoutWrapper";
import mixins from "../../mixins";
import moment from "moment";

export class AddVideos extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("AddVideos");
    this.state = {
      data: [],
      rowcount: 0,
      search: {
        limit: 20,
        orderBy: "updatedAt",
        orderDirection: "DESC",
        page: 1,
        filter: {
          session_id: props?.urlparams?.session_id,
        },
      },
      editData: {},
      viewMode: false,
      editMode: false,
      popupfields: {},
      popupdata: {},
    };
    this.tablinks = mixins.tablinks;
    this.columns = [
      {
        dataIndex: "content_title",
        title: "Video Name",
      },
      {
        dataIndex: "attach_file",
        title: "Video Link",
      },
      {
        dataIndex: "createdAt",
        title: "Created on",
      },
      {
        dataIndex: "created_by_name",
        title: "Created by",
      },
    ];
    this.modalFields = [
      {
        type: "text",
        name: "content_title",
        label: "Video Title",
        required: true,
      },
      {
        type: "text",
        name: "attach_file",
        label: "Video Link",
        required: true,
      },
      {
        type: "text",
        name: "content_description",
        label: "Video Description",
        fieldclass: "col-12",
      },
    ];
    this.modalRef = React.createRef();
  }

  getGradeLevelParams() {
    return {
      grade: this.props.urlparams.grade,
      // level: this.props.urlparams.level,
      // school_id: this.props.urlparams.school_id,
      // class_id: this.props.urlparams.class_id,
      curriculum_id: this.props.urlparams.curriculum_id,
      session_id: this.props.urlparams.session_id,
    };
  }

  getPillarParams() {
    return this.props.urlparams.type;
  }

  downloadFile(fileId) {
    let authToken = window.store.getState().root.auth;
    // console.log({ authToken: authToken });
    return (
      app.api
        .request(app.apis().fileDownloader)
        .urltransform({ attachmentId: fileId }).url +
      "?auth=" +
      authToken
    );
  }

  sumitForm(event, data, form) {
    console.log({ addVideos: data });
  }

  componentDidMount() {
    this.fetchData();
  }

  addModalFields() {
    let fields = (this.modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    this.setState({
      popupfields: fields,
      popupdata: formdata,
    });
  }

  fetchData() {
    let payload = {
      session_id: this.props.urlparams.session_id,
      content_type: "VIDEO",
      school_id: localStorage.getItem("school_id") || "",
      class_id: localStorage.getItem("class_id") || "",
    };
    if(this.props.urlparams.session_id) {
    this.props.api.apiGetContentBySession(payload).then(({ data, total }) => {
      console.log("apiGetContentBySession: ", data);
      this.setState({
        data,
        rowcount: total,
      });
    });
    }
  }

  openModal() {
    this.modalRef.current.click();
  }

  getAssignedObj(v) {
    let fields = (this.modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let editformdata = Object.map(fields, (v, k) => "");
    let assignedObj = Object.assign(editformdata, v);
    return assignedObj;
  }

  editAction(v) {
    // console.log("edit: ", v);
    this.openModal();
    this.setState((prevState) => ({
      popupdata: {
        ...Object.only(v, ["video_title", "video_description", "video_id"]),
      },
      editMode: true,
      viewMode: false,
      editData: v,
    }));
  }

  viewAction(v) {
    // console.log("view: ", v);
    this.openModal();
    this.setState((prevState) => ({
      popupdata: {
        ...Object.only(v, ["content_title", "attach_file", "content_description"]),
      },
      editMode: false,
      viewMode: true,
    }));
  }

  deleteAction(item) {
    console.log("delete ", item);
    let payload = {
      content_type: "VIDEO",
      is_active: false,
    };

    confirm(`Do you really want to delete these video?`).promise.then(() => {
      this.props.api
      .apiUpdateConetent(payload, {
        content_id: item.content_id,
      })
      .then((res) => {
        this.fetchData();
      }).catch((error) => {
        console.error(error);
      });
    });
  }
  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      (state) => this.fetchData()
    );
  }

  closeModal() {
    document.querySelector("#video_modal #bsmodalclose").click();
  }

  validateForm(form) {
    // if (form.link.search("youtu.be/") > 0) {
    //   return false;
    // } else {
    return false;
    // }
  }

  handleSubmit(e, data, form) {
    e.preventDefault();

    const resolveList = () => {
      this.fetchData();
      form.reset();
    };
    const formData = e.target.getData();
    let formValidation = this.validateForm(formData);
    if (!formValidation) {
      console.log("formData", formData);
      let payload = {
        session_id: this.props?.urlparams?.session_id,
        content: [
          {
            content_type: "VIDEO",
            ...formData
          },
        ],
      };
      console.log("payload", payload);
      this.props.api.apiCreateConetent(payload).then((data) => {
        this.setState({
          data: data,
        });
        console.log("all ppt: ", this.state.data);
        success("Video Added Successfully");
        this.closeModal();
        resolveList();
      });
    } else {
      alert("Video Link is Invalid");
    }
  }

  getBreadCums() {
    if (this.props.urlparams.grade) {
      return `Content Management / ${
        this.props.urlparams.grade
      } Grade / Courses / ${this.props.urlparams.type.toTitleCase()} / Add Session`;
    } else {
      return "Content Management / Sessions";
    }
  }

  getBackLink() {
    if (this.props.urlparams.grade) {
      let backLink = `/curriculum/pillarspage?grade=${this.props.urlparams.grade}&level=${this.props.urlparams.level}&curriculum_id=${this.props.urlparams.curriculum_id}&session_id=${this.props.urlparams.session_id}`;
      return backLink;
    } else {
      return "/curriculum/sessions";
    }
  }

  render() {
    let { tablinks, columns } = this;
    let {
      data = [],
      rowcount,
      editData,
      popupdata,
      popupfields,
      editMode,
      viewMode,
    } = this.state;
    let finalData = [];
    if (data.length) {
      data.map((ele) => {
        ele.createdAt = moment(ele.createdAt).format("DD/MM/YYYY");
        finalData.push(ele);
      });
    }
    return (
      <LayoutWrapper
        title="Content Management"
        back={this.getBreadCums()}
        backlink={this.getBackLink()}
      >
        <AppLinkTabs data={tablinks} className="mb-0"></AppLinkTabs>
        <div className="row m-auto">
          <div className="col"></div>
          <div className="mb-4"></div>
        </div>
        <div className="row m-auto">
          <div className="col-12 mb-4">
            <Link
              className={Object.className({
                "btn add-btn ml-auto": true,
                hidden: this.props.urlparams.readonly == 1 ? true : false,
              })}
              ref={this.modalRef}
              data-bs-toggle="modal"
              data-bs-target="#video_modal"
              onClick={(...args) => this.addModalFields(...args)}
            >
              <i className="fa fa-plus"></i>
              Add Video
            </Link>
          </div>
        </div>

        <div className="row addVideos">
          <div className="col-12">
            {!!data.length && (
              <AppTable
                data={finalData}
                columns={columns}
                onNext={() => this.onNext()}
                onPrev={() => this.onPrev()}
                onChange={(...arg) => this.onPageChange(...arg)}
                total={rowcount}
                reorder={true}
                deleteAction={(v) => this.deleteAction(v)}
                // editAction={(v) => this.editAction(v)}
                viewAction={(v) => this.viewAction(v)}
                editable={false}
                deletable={this.props.urlparams.readonly == 1 ? false : true}
                pageSize={50}
                // showPagination={false}
                targetType="tap"
              ></AppTable>
            )}
            {data.length == 0 && <div className="empty_layout"></div>}
          </div>
        </div>

        <div className="row m-auto">
          <div className="col-12 mt-4">
            <Link
              to={`/curriculum/sessions/addppt${Object.encodeuri(
                this.getGradeLevelParams()
              )}&type=${this.getPillarParams()}&${Object.QueryString({
                new: 1,
              })}`}
              className="btn add-btn ms-3"
            >
              Save & Next
            </Link>
            <Link
              to={`/curriculum/sessions/addppt${Object.encodeuri(
                this.getGradeLevelParams()
              )}&type=${this.getPillarParams()}&${Object.QueryString({
                new: 1,
              })}`}
              className="btn btn_primary grey col col-md-1 float-right"
            >
              Skip
            </Link>
          </div>
        </div>
        <ClassModal
          id="video_modal"
          title={`${editMode ? "Update Video" : "Add Video"}`}
          submitText={`${editMode ? "Update Video" : "Add Video"}`}
          editdata={editData}
          data={popupdata}
          fields={popupfields}
          disableallfields={viewMode ? true : false}
          onSubmit={(...args) => this.handleSubmit(...args)}
          onClose={() =>
            this.setState({
              popupdata: {},
              editData: {},
              editMode: false,
              viewMode: false,
            })
          }
        />
      </LayoutWrapper>
    );
  }
}

export default connect(AddVideos);
