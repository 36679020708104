import React, { Component } from "react";
import {
  CheckMarkIcon,
  PauseIcon,
  ToDoListIcon,
  WorkInProgressIcon,
} from "src/imagepath/imagepath";
import _enum from "src/mixins/enum";

const status = [
  {
    key: "TODO",
    label: "To-Do",
    color: "bg-warning-1",
    icon: ToDoListIcon,
    index: 0,
  },
  {
    key: "INPROGRESS",
    label: "In-progress",
    color: "bg-warning-1",
    icon: WorkInProgressIcon,
    index: 1,
  },
  { key: "HOLD", label: "Hold", color: "bg-warning-1", icon: PauseIcon, index: 2 },
  {
    key: "COMPLETED",
    label: "Completed",
    color: "bg-warning-1",
    icon: CheckMarkIcon,
    index: 3,
  },
];

export class TaskKpi extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("TaskKpi");

    this.state = {
      loader: false,
      taskcount: [],
    };
  }

  componentDidMount() {
    this.getTaskCounts();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.school_id !== this.props.school_id) {
      this.getTaskCounts(); 
    }else if (prevProps.priority !== this.props.priority) {
      this.getTaskCounts(); 
    }else if (prevProps.taskStatus !== this.props.taskStatus) {
      this.getTaskCounts(); 
    }else if (prevProps.dueIn !== this.props.dueIn) {
      this.getTaskCounts(); 
    }
  }

  getTaskCounts() {
    this.setState({
      loader: true,
    });
    let payload = { 
      school_id: this.props.school_id || undefined, 
      priority: this.props.priority || undefined,
      task_status: this.props.taskStatus || undefined,
      due_in: this.props.dueIn || undefined,
     };
    if (localStorage.getItem("currentAdminRole") == "SCHOOL_COORDINATOR") {
      payload = {
        school_id: localStorage.getItem("school_id"),
        priority: this.props.priority,
        task_status: this.props.taskStatus,
        due_in: this.props.dueIn,
      };
    }
    this.props.api
      .apiGetTaskCount(payload)
      .then(({ data }) => {
        const statusIndexMap = status.reduce((map, item) => {
          map[item.key] = item.index;
          return map;
        }, {});
        const sortedTaskCountData = data.sort((a, b) => {
          return statusIndexMap[a.task_status] - statusIndexMap[b.task_status];
        });
        console.log({ sortedTaskCountData });
        this.setState({
          loader: false,
          taskcount: sortedTaskCountData,
        });
      })
      .catch((error) => {
        this.setState({
          loader: false,
        });
      });
  }

  render() {
    let { fields, loader, taskcount } = this.state;

    return (
      <div className="row">
        {status.map((task) => {
          let t = taskcount?.find((st) => st.task_status === task.key);
          return (
            <div className="col-6 col-lg-3 col-md-6 col-sm-12">
              <div className={`card ${task.color} text-white task_count_card`}>
                <div className="row py-3 mx-auto">
                  <div className="col-4 col-lg-3 text-center border-right align-content-center">
                    <img src={task.icon} alt="link icon" />
                  </div>
                  <div className="col-8 col-lg-9 text-white d-flex align-items-end">
                    <h2 className="text-white mb-0 me-3 lh-1">{t?.total || 0}</h2>
                    Total {task?.label}
                  </div>
                </div>
                <div className="row mx-auto bottom_box py-3">
                  <div className="col-4 text-center border-right p-0">
                    High
                    <br />
                    <h3>{t?.highPriority || 0}</h3>
                  </div>
                  <div className="col-4 text-center border-right p-0">
                    Medium
                    <br />
                    <h3>{t?.mediumPriority || 0}</h3>
                  </div>
                  <div className="col-4 text-center p-0">
                    Low 
                    <br />
                    <h3>{t?.lowPriority || 0}</h3>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default connect(TaskKpi);
