import React, { Component } from "react";
import { Link } from "react-router-dom";
import { WarnIcon } from "src/imagepath/imagepath";

export class DeleteEventModal extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("DeleteEventModal");
    this.state = {
      delete_all_recurrent: false,
      showReason: false,
    };
  }
  componentDidMount() {
    window.DeleteEventModal = this;
  }
  componentDidUpdate() {}

  closemodal() {
    this.setState({
      delete_all_recurrent: false,
      showReason: false,
    });
    document.querySelector("#delete_event_modal #bsmodalclose").click();
  }

  submitForm(e, data) {
    e.preventDefault();
    let payload = {
      ...data,
      delete_all_recurrent: this.state.delete_all_recurrent,
    };
    this.props.onSubmit(payload);
    this.closemodal();
  }

  render() {
    let { props } = this;
    let { id, title, data } = props;
    let { showReason } = this.state;
    // console.log(data);
    return (
      <AppModalBs
        id={id}
        title={title}
        className="modal-md modal-dialog-top"
        onClose={(...args) => this.closemodal(...args)}
      >
        {showReason ? (
          <div>
            <AppForm
              ref={this.form}
              className="flex flex-col gap-4 bg-white p-4"
              onSubmit={(...args) => this.submitForm(...args)}
            >
              <AppInput
                type="textarea"
                name="delete_reason"
                label="Reason"
                required={true}
              ></AppInput>
              <div className="row no-gutters">
                <Link
                  onClick={() => {
                    this.setState({
                      delete_all_recurrent: false,
                      showReason: false,
                    });
                  }}
                  className="btn btn_primary col col-md-1 rounded-2 ml-auto grey"
                >
                  Cancel
                </Link>
                <button
                  className="btn save-btn col col-md-1 rounded-2 ml-auto ms-2 me-2"
                  type="submit"
                >
                  Delete
                </button>
              </div>
            </AppForm>
          </div>
        ) : (
          <div className="text-center">
            <img src={WarnIcon} alt="link icon" className="mb-2" />
            {(data?.is_recurrent && ["OPEN"].includes(data?.event_status)) ? (
              <>
                <h3>
                  This is an recurring event.
                  <br /> So are you sure want to
                  <br /> delete this?
                </h3>
                <div className="row no-gutters justify-content-center m-2">
                  <Link
                    className="btn btn_primary rounded-2 col col-md-6 grey mr-1"
                    onClick={() => {
                      this.setState({
                        delete_all_recurrent: false,
                        showReason: true,
                      });
                    }}
                  >
                    Delete single instance
                  </Link>
                  <button
                    className="btn save-btn rounded-2 col col-md-5 ml-1"
                    onClick={() => {
                      this.setState({
                        delete_all_recurrent: true,
                        showReason: true,
                      });
                    }}
                  >
                    Delete full series
                  </button>
                </div>
              </>
            ) : (
              <>
                <h3>
                  Are you sure want to
                  <br /> delete this event?
                </h3>
                <div className="row no-gutters justify-content-center m-2">
                  <Link
                    className="btn btn_primary rounded-2 col col-md-6 grey mr-1"
                    onClick={() => this.closemodal()}
                  >
                    Cancel
                  </Link>
                  <button
                    className="btn save-btn rounded-2 col col-md-5 ml-1"
                    onClick={() => {
                      this.setState({
                        deletionType: "SINGLE",
                        showReason: true,
                      });
                    }}
                  >
                    Delete
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </AppModalBs>
    );
  }
}

export default connect(DeleteEventModal);
