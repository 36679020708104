import React, { Component } from 'react'

export class ConfigPanel extends Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
    }
    setvalue = (name,value)=>this.props.setstore('config.'+name,value);

    setValues(e) {
        e.preventDefault();
        let data = this.form.current.getData();
        this.setvalue("colors.primary",data.primary);
        this.setvalue("colors.secoundary",data.secoundary);
        this.setvalue("logo",data.logo);
    }
    render() {
        let { props,form,setvalue } = this;
        let { config } = props.store;
        let { colors } = config;
        return (
            <li className="nav-item dropdown">
                <a href="#" className="dropdown-toggle nav-link" data-bs-toggle="dropdown">
                    <i className="fa fa-cogs" />
                </a>
                {
                    <form className="dropdown-menu notifications p-4 w-80" onSubmit={(e)=>this.setValues(e)} ref={form} >
                    <AppInput type="color" label="Primary" name="primary" value={colors.primary} onChange={(e)=>setvalue('colors.primary',e.target.value)} ></AppInput>
                    <AppInput type="color" label="Secoundary" name="secoundary" defaultValue={colors.secoundary} onChange={(e)=>setvalue('colors.secoundary',e.target.value)} ></AppInput>
                    <AppInput type="text" label="App Logo" name="logo" defaultValue={config.logo} onChange={(e)=>setvalue('logo',e.target.value)} ></AppInput>
                    <AppInput type="text" label="App Name" name="name" defaultValue={config.name} onChange={(e)=>setvalue('name',e.target.value)} ></AppInput>
                    {/* <button className='btn bg-primary text-white w-full'>SET</button> */}
                    </form>
                }
                
            </li>
        )
    }
}

export default connect(ConfigPanel);