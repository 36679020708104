import { Component } from "react";
import AttendanceDetailsAdmin from "./AttendanceDetailsAdmin";
import AttendanceDetailsCordinator from "./AttendanceDetailsCordinator";
import LayoutWrapper from "./LayoutWrapper";

export class AttendanceManagement extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("AttendanceManagement");
  }

  componentDidMount() {}
  render() {
    let isSuperAdmin =
      localStorage.getItem("currentAdminRole") == "SUPER_ADMIN";
    return (
      <>
        <LayoutWrapper title="Attendance Management" back="">
          {isSuperAdmin ? (
            <AttendanceDetailsAdmin />
          ) : (
            <AttendanceDetailsCordinator />
          )}
        </LayoutWrapper>
      </>
    );
  }
}

export default connect(AttendanceManagement);
