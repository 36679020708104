import React, { Component } from "react";
import "./curriculum.scss";
import ReactHtmlParser from "react-html-parser";

export class ContentCard extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("ContentCard");
  }
  render() {
    let { grade, level, content } = this.props;
    return (
      <>
        <div className="card content_card">
          <div className="card-header row g-0 m-0">
            <div className="circle_box col-12 col-lg-6 me-2">
              <p className="title">{grade}</p>
              <p className="sub_title">Grade</p>
            </div>
            <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
              <h2 className="main_title">Level - {level}</h2>
            </div>
          </div>
          <div className="card-body">
            <p className="content" id="content_body">
              {ReactHtmlParser(content)}
            </p>
          </div>
          <div className="card-footer">
            <button className="btn btn-success btn-block">Explore</button>
          </div>
        </div>
      </>
    );
  }
}

export default connect(ContentCard);
