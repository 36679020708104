import React, { Component } from "react";
import { Link } from "react-router-dom";
import ClassModal from "../../ClassModal";
import LayoutWrapper from "../../LayoutWrapper";
import mixins from "../../mixins";
import { ToastContainer, toast } from "react-toastify";

export class AddPPT extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("AddPPT");
    this.state = {
      data: [],
      rowcount: 0,
      search: {
        limit: 20,
        orderBy: "updatedAt",
        orderDirection: "DESC",
        page: 1,
        filter: {
          session_id: props?.urlparams?.session_id,
        },
      },
      editData: {},
      viewMode: false,
      editMode: false,
      popupfields: {},
      popupdata: {},
    };
    this.tablinks = mixins.tablinks;
    this.columns = [
      {
        dataIndex: "content_title",
        title: "Manual Name",
      },
      {
        dataIndex: "attach_file",
        title: "Attachments",
        render: (text, record) => {
          // console.log({ text, record });
          if (text) {
            return (
              <>
                <a
                  className="link_btn"
                  href={this.downloadFile(text)}
                  target="_blank"
                >
                  Download
                </a>
              </>
            );
          } else {
            return <>-</>;
          }
        },
      },
      {
        dataIndex: "content_description",
        title: "Description",
        render: (text, record) => {
          return (
            <span
              className="text_truncate w-200px cursor-pointer"
              title={record?.content_description}
              onClick={() => alert(record?.content_description)}
            >
              {record?.content_description}
            </span>
          );
        },
      },
      {
        dataIndex: "createdAt",
        title: "Created on",
        render: (text) => {
          return <>{new Date(`${text}`).toLocaleDateString()}</>;
        },
      },
      {
        dataIndex: "created_by_name",
        title: "Created by",
      },
    ];
    this.modalFields = [
      {
        type: "text",
        name: "content_title",
        label: "Manual Name",
        maxLength: 25,
        required: true,
      },
      {
        type: "file",
        name: "attach_file",
        label: "Upload Manual",
        fileType: "ppt",
        accept: ".ppt, .pptx,.doc,.docx,.pdf",
      },
      {
        type: "text",
        name: "content_description",
        label: "Manual Description",
        fieldclass: "col-12",
      },
    ];
    this.modalRef = React.createRef();
  }

  getGradeLevelParams() {
    return {
      grade: this.props.urlparams.grade,
      // level: this.props.urlparams.level,
      curriculum_id: this.props.urlparams.curriculum_id,
      // school_id: this.props.urlparams.school_id,
      // class_id: this.props.urlparams.class_id,
      session_id: this.props.urlparams.session_id,
    };
  }

  getPillarParams() {
    return this.props.urlparams.type;
  }

  downloadFile(fileId) {
    let authToken = window.store.getState().root.auth;
    // console.log({ authToken: authToken });
    return (
      app.api
        .request(app.apis().fileDownloader)
        .urltransform({ attachmentId: fileId }).url +
      "?auth=" +
      authToken
    );
  }

  sumitForm(event, data, form) {
    console.log({ addPPT: data });
  }

  componentDidMount() {
    this.fetchData();
  }

  addModalFields() {
    let fields = (this.modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    this.setState({
      popupfields: fields,
      popupdata: formdata,
    });
  }
  // sortData(arrayOfObjects) {
  //   let sortedData = arrayOfObjects.sort((a, b) => {
  //     const dateA = new Date(a.createdAt);
  //     const dateB = new Date(b.createdAt);
  //     return dateB - dateA;
  //   });

  //   return sortedData
  // }

  fetchData() {
    let payload = {
      session_id: this.props.urlparams.session_id,
    };
    if(this.props.urlparams.session_id) {
    this.props.api
      .apiGetContentBySession({
        session_id: this.props.urlparams.session_id,
        content_type: "PPT",
        school_id: localStorage.getItem("school_id") || "",
        class_id: localStorage.getItem("class_id") || "",
      })
      .then(({ data, total }) => {
        console.log("apiGetContentBySession: ", data);
        // let sortedData = this.sortData(data)
        this.setState({
          data: data,
          rowcount: total,
        });
      });
    }
  }

  openModal() {
    this.modalRef.current.click();
  }

  editAction(v) {
    // console.log("edit: ", v);
    this.openModal();
    this.setState((prevState) => ({
      popupdata: {
        ...Object.only(v, [
          "content_title",
          "content_description",
          "attach_file",
        ]),
      },
      editMode: true,
      viewMode: false,
      editData: v,
    }));
  }

  viewAction(v) {
    // console.log("view: ", v);
    this.openModal();
    this.setState((prevState) => ({
      popupdata: {
        ...Object.only(v, [
          "content_title",
          "content_description",
          "attach_file",
        ]),
      },
      editMode: false,
      viewMode: true,
    }));
  }

  deleteAction(v) {
    console.log("delete ", v);

    let payload = {
      content_type: "PPT",
      is_active: false,
    };

    confirm(`Do you really want to delete these manual?`).promise.then(() => {
      this.props.api
      .apiUpdateConetent(payload, {
        content_id: v.content_id,
      })
      .then((res) => {
        this.fetchData();
      }).catch((error) => {
        console.error(error);
      });
    });
  }
  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      (state) => this.fetchData()
    );
  }

  closeModal() {
    document.querySelector("#ppt_modal #bsmodalclose").click();
  }

  handleSubmit(e, data, form) {
    e.preventDefault();

    let formData = e.target.getData();
    console.log("formData", formData);

    const resolveList = () => {
      this.fetchData();
      form.reset();
    };

    if (this.state.editMode) {
      let payload = {
        ...formData,
        content_type: "PPT",
      };
      this.props.api
        .apiUpdateConetent(payload, {
          content_id: this.state.editData.content_id,
        })
        .then((res) => {
          toast.success("Manual Updated Successfully", {
            autoClose: 3000,
            closeButton: false,
            hideProgressBar: true,
          });
          this.closeModal();
          resolveList();
        });
    } else {
      let payload = {
        session_id: this.props.urlparams.session_id,
        content: [
          {
            content_type: "PPT",
            ...formData,
          },
        ],
      };
      this.props.api.apiCreateConetent(payload).then((data) => {
        this.setState({
          data: data,
        });
        toast.success("Manual Added Successfully", {
          autoClose: 3000,
          closeButton: false,
          hideProgressBar: true,
        });
        this.closeModal();
        resolveList();
      });
    }
  }

  getBreadCums() {
    if (this.props.urlparams.grade) {
      return `Content Management / ${
        this.props.urlparams.grade
      } Grade / Courses / ${this.props.urlparams.type.toTitleCase()} / Add Session`;
    } else {
      return "Content Management / Sessions";
    }
  }

  getBackLink() {
    if (this.props.urlparams.grade) {
      let backLink = `/curriculum/pillarspage?grade=${this.props.urlparams.grade}&level=${this.props.urlparams.level}&curriculum_id=${this.props.urlparams.curriculum_id}&session_id=${this.props.urlparams.session_id}`;
      return backLink;
    } else {
      return "/curriculum/sessions";
    }
  }

  render() {
    let { tablinks, columns } = this;
    let {
      data = [],
      rowcount,
      editData,
      popupdata,
      popupfields,
      editMode,
      viewMode,
    } = this.state;
    return (
      <LayoutWrapper
        title="Content Management"
        back={this.getBreadCums()}
        backlink={this.getBackLink()}
      >
        <ToastContainer position="top-right" />
        <AppLinkTabs data={tablinks} className="mb-0"></AppLinkTabs>
        <div className="row m-auto">
          <div className="col"></div>
          <div className="mb-4"></div>
        </div>
        <div className="row m-auto">
          <div className="col-12 mb-4">
            <Link
              className={Object.className({
                "btn add-btn ml-auto": true,
                hidden: this.props.urlparams.readonly == 1 ? true : false,
              })}
              ref={this.modalRef}
              data-bs-toggle="modal"
              data-bs-target="#ppt_modal"
              onClick={(...args) => this.addModalFields(...args)}
            >
              <i className="fa fa-plus"></i>
              Add Manual
            </Link>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            {!!data.length && (
              <AppTable
                data={data}
                columns={columns}
                onNext={() => this.onNext()}
                onPrev={() => this.onPrev()}
                onChange={(...arg) => this.onPageChange(...arg)}
                total={rowcount}
                reorder={true}
                deleteAction={(v) => this.deleteAction(v)}
                editAction={(v) => this.editAction(v)}
                viewAction={(v) => this.viewAction(v)}
                editable={this.props.urlparams.readonly == 1 ? false : true}
                deletable={this.props.urlparams.readonly == 1 ? false : true}
                pageSize={50}
                targetType="tap"
              ></AppTable>
            )}
            {data.length == 0 && <div className="empty_layout"></div>}
          </div>
        </div>

        <div className="row m-auto">
          <div className="col-12 mt-4">
            <Link
              to={`/curriculum/sessions/addhandouts${Object.encodeuri(
                this.getGradeLevelParams()
              )}&type=${this.getPillarParams()}&${Object.QueryString({
                new: 1,
              })}`}
              className="btn add-btn ms-3"
            >
              Save & Next
            </Link>
            <Link
              to={`/curriculum/sessions/addhandouts${Object.encodeuri(
                this.getGradeLevelParams()
              )}&type=${this.getPillarParams()}&${Object.QueryString({
                new: 1,
              })}`}
              className="btn btn_primary grey col col-md-1 float-right"
            >
              Skip
            </Link>
          </div>
        </div>
        <ClassModal
          id="ppt_modal"
          title={`${editMode ? "Update Manual" : "Add Manual"}`}
          submitText={`${editMode ? "Update Manual" : "Add Manual"}`}
          editdata={editData}
          data={popupdata}
          fields={popupfields}
          disableallfields={viewMode ? true : false}
          onSubmit={(...args) => this.handleSubmit(...args)}
          onClose={() =>
            this.setState({
              popupdata: {},
              editData: {},
              editMode: false,
              viewMode: false,
            })
          }
        />
      </LayoutWrapper>
    );
  }
}

export default connect(AddPPT);
