import { Component } from "react";
import LayoutWrapper from "../LayoutWrapper";
import AttendanceDetailsHistory from "./AttendanceDetailsHistory";

export class AttendanceHistory extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("AttendanceHistory");
  }

  componentDidMount() {}
  render() {
    let isSuperAdmin =
      localStorage.getItem("currentAdminRole") == "SUPER_ADMIN";
    return (
      <>
        <LayoutWrapper title="Attendance History" back="">
           <AttendanceDetailsHistory/>
        </LayoutWrapper>
      </>
    );
  }
}

export default connect(AttendanceHistory);
