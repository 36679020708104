import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import _enum from "src/mixins/enum";
import LayoutWrapper from "./LayoutWrapper";
import AttendanceSearch from "./AttendanceSearch";

export class AttendanceDetailsCordinator extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("AttendanceDetailsCordinator");
    this.state = {
      data: [],
      selectedRowKeys: [],
      selectedRows: [],
      touchedRows:[],
      initiallySelectedRowKeys: [],
      rowcount: 0,
      search: {
      },
      editData: {},
      viewMode: false,
      editMode: false,
      popupfields: {},
      popupdata: {},
      loader: false,
      schools: [],
      student_data:[],
    };
    this.columns = [
      {
        dataIndex: "user_id",
        title: "User ID",
      },
      {
        dataIndex: "curriculum_name",
        title: "Class Name",
      },
      {
        dataIndex: "section_name",
        title: "Section",
      },
      {
        dataIndex: "student_name",
        title: "Student Name",
      },
    ];
  }

  componentDidMount() {
    this.fetchList();
  }

  fetchList(data) {
    this.setState({
      loader: true,
    });
    let payload = {
      ...data,
      ...this.state.search,
    };
      this.setState({
        search: {
          ...this.state.search,
        },
      });

    this.props.api
      .apiGetExistingAttendanceBySectionId({ section_id: payload.section_id},Object.only(payload,["profile_id"]))
      .then(({ data, total }) => {
        const presentRows = data.filter(
          (row) => row.attendance_status === "PRESENT"
        );
        const selectedRowKeys = presentRows.map((row) => row.profile_id);

        this.setState({
          data: data,
          student_data:data,
          rowcount: total,
          loader: false,
          selectedRowKeys,
          selectedRows: presentRows,
          initiallySelectedRowKeys: selectedRowKeys,
        });
      })
      .catch((err) => {
        this.setState({
          loader: false,
        });
        toast.error("Error fetching attendance");
      });
  }

  downloadFile() {}

  onNext() {}

  onPrev() {}

  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      }
      // () => this.fetchList()
    );
  }

  onSearch(data) {
    console.log("onSearch data: ", data);

    this.setState(
      {
        search: {
          ...this.state.search,
          ...Object.except(data, ["class_id"]),
        },
      },
      () => {
        this.fetchList();
      }
    );
  }

  onSelectedRowKeysChange = (selectedRowKeys, selectedRows) => {
    console.log({ selectedRowKeys, selectedRows });

    const { data } = this.state;
    // Find rows whose status changed (either checked or unchecked)
    const touchedRows = data.filter((row) => {
      const wasPreviouslySelected = this.state.selectedRowKeys.includes(
        row.profile_id
      );
      const isNowSelected = selectedRowKeys.includes(row.profile_id);
      return wasPreviouslySelected !== isNowSelected; // Status changed
    });
    this.setState({
      selectedRowKeys: selectedRows.map((row) => row.profile_id),
      selectedRows,
      touchedRows:[
        ...this.state.touchedRows,
        ...touchedRows
      ], // Keep track of touched rows
    });
  };

  updateAttendance = () => {
    const { touchedRows } = this.state;

    // Format the touched rows to send to the API
    const updatedRows = touchedRows.map((row) => ({
      profile_id: row.profile_id,
      attendance_status: this.state.selectedRowKeys.includes(row.profile_id)
        ? "PRESENT"
        : "ABSENT",
    }));

    // Check if there are any changes to send
    if (updatedRows.length === 0) {
      console.log("No changes to update.");
      return;
    }
    let payload={
      section_id:this.state.search?.section_id,
      attendance:updatedRows
    }
    this.props.api
      .apiUpdateAttendance(payload)
      .then((response) => {
        toast.success("Attendance updated successfully");
        this.fetchList();
      })
      .catch((error) => {
        toast.error("Error updating attendance");
      });
  };

  render() {
    let { data = [], rowcount, loader, search, selectedRowKeys } = this.state;
    let { columns } = this;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectedRowKeysChange,
    };
    return (
      <>
        {loader && (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        )}
          <ToastContainer position="top-right" />
          <AttendanceSearch student_data={this.state.student_data}
            onSearch={(...arg) => this.onSearch(...arg)}
          />
          <div className="row schoolmanagement">
            <div className="col-12">
              <div></div>
              {!!data.length && (
                <AppTable
                  data={data}
                  columns={columns}
                  onNext={() => this.onNext()}
                  onPrev={() => this.onPrev()}
                  onChange={(...arg) => this.onPageChange(...arg)}
                  total={rowcount}
                  reorder={true}
                  isRowDeletable={() => false}
                  isRowEditable={() => false}
                  viewable={false}
                  // targetType="tap"
                  isresponsive={true}
                  checkbox={true}
                  rowSelection={rowSelection}
                  isCustomRowKey={true}
                  customRowKey={"profile_id"}
                ></AppTable>
              )}
              {data.length == 0 && (
                <div className="empty_attendance_layout bg-white"></div>
              )}
            </div>
          </div>
          {data.length > 0 && (
            <div className="d-flex justify-content-end bg-white">
              <div className="col-2 m-2">
                <button
                  type="button"
                  className="btn btn-success btn-block w-100"
                  onClick={() => this.updateAttendance()}
                >
                  Update
                </button>
              </div>
            </div>
          )}
      </>
    );
  }
}

export default connect(AttendanceDetailsCordinator);
