import React, { Component } from "react";
export class AssessmentSearch extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      disable: false,
      fields: [
        {
          name: "assessment_id",
          placeholder: "Select Assessment",
          type: "select",
          options: [],
          required: true,
        },
        {
          name: "section_id",
          placeholder: "Select Section",
          type: "select",
          options: [],
          onChange: (data) => {
            this.getStudents(data[0]?.section_id);
          },
          required: true,
        },
        {
          name: "profile_id",
          placeholder: "Select Students",
          type: "select",
          options: [],
          multi: true,
          required: true,
        },
      ],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.assessmentData !== this.props.assessmentData) {
      // this.getTaskCounts();
      if (this.props.assessmentData?.length > 0) {
        let field = this.state.fields.find((o) => o.name === "assessment_id");
        field.options = this.props.assessmentData?.options(
          "assessment_id",
          "assessment_name"
        );
        this.setState({
          fields: this.state.fields,
        });
      }
      this.getSections();
    }
  }
  getSections() {
    this.props.api
      .apiGetSectionByClassId({ class_id: this.props.urlparams.class_id })
      .then(({ data }) => {
        let field = this.state.fields.find((o) => o.name === "section_id");
        field.options = data?.options("section_id", "name");
        field.defaultValue = null;
        this.setState({
          fields: this.state.fields,
        });
      })
      .catch(() => {});
  }
  getStudents(section_id) {
    let data = this.refs.form.form.current.getData();
    this.props.api
      .apiGetUnselectedStudents({
        section_id: section_id,
        assessment_id: data?.assessment_id,
      })
      .then(({ data }) => {
        let field = this.state.fields.find((o) => o.name === "profile_id");
        field.options = data?.items
          ?.map((item) => {
            return {
              profile_id: item.profile_id,
              name: item?.profiles?.[0]?.name,
            };
          })
          ?.options("profile_id", "name");
        field.defaultValue = null;
        this.setState({
          fields: this.state.fields,
        });
      })
      .catch(() => {});
  }

  trimObjectValues(obj) {
    const trimmedObj = {};
    for (const key in obj) {
      if (typeof obj[key] === "string") {
        trimmedObj[key] = obj[key].trim();
      } else {
        trimmedObj[key] = obj[key];
      }
    }
    return trimmedObj;
  }

  onSearch() {
    let data = this.refs.form.form.current.getData();
    // console.log("onSearch formData", data);

    data = this.trimObjectValues(data);
    data = Object.filter(data, (val) => val);
    // console.log("data on search", data);
    this.props.onSearch && this.props.onSearch(data);
  }

  clearTextData = () => {
    this.setState({
      fields: this.state.fields.map((field) => ({ ...field, value: "" })),
    });
    let data = this.refs.form.form.current.getData();
    data = Object.filter(data, (val) => "");
    this.props.onSearch && this.props.onSearch(data);
  };

  handleInputChange = (i, event) => {
    const newFields = [...this.state.fields];
    if (newFields[i].name != "status") newFields[i].value = event.target.value;
    this.setState({ fields: newFields });
  };

  componentDidMount() {}

  render() {
    let { fields, disable } = this.state;

    return (
      <AppForm className="row filter-row" ref="form">
        {fields.map((field, i) => (
          <div className="col-sm-6 col-md-3" key={i}>
            <AppInputFocus {...field} disabled={disable}></AppInputFocus>
          </div>
        ))}
        <div className="col-sm-6 col-md-3">
          <button
            className="btn btn-success btn-block w-100"
            type="submit"
            disabled={disable}
            onClick={(e) => {
              let data = this.refs.form.form.current.getData();
              let student_ids = data.profile_id?.splitBy(",");
              let nameoptions = this.state.fields.find(
                (o) => o.name === "profile_id"
              )?.options;
              let payload = student_ids?.map((id) => {
                return {
                  profile_id: id,
                  session_id: this.props.urlparams.session_id,
                  assessment_id: data.assessment_id,
                  student_name: nameoptions.find((i) => i.profile_id === id)
                    ?.name,
                  section_id: data.section_id,
                };
              });
              this.setState({
                disable: true,
              });
              this.props.startAssessment(payload);
            }}
          >
            Start Assessment
          </button>
        </div>
        <span className="clear-all" onClick={() => this.clearTextData()}>
          Clear Filter
        </span>
      </AppForm>
    );
  }
}

export default connect(AssessmentSearch);
