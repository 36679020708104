import React, { Component } from "react";

// const CustomClearButton = (props) => (
//   <div
//     className="react-dropdown-select-clear"
//     onClick={(e, props) => {
//       e.stopPropagation();
//       console.log("in custom clear btn", props);
//     }}
//   >
//     x
//   </div>
// );
export class StudentSearch extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      fields: [
        {
          type: "select",
          name: "class_name",
          label: "Class",
          placeholder: "Search here",
          labelClass: "text-bold",
          options: [],
          clearable: true,
          // clearRenderer: (props) => <CustomClearButton {...props} />,
          clearSearchValue: (data) => {
            // this.clearClassAndSection();
          },
          onChange: (data) => {
            props?.setClassName(data[0]?.class_name);
            this.getSectionsByClassId(data[0]?.class_id);
          },
        },
        {
          type: "select",
          name: "section_name",
          label: "Section",
          placeholder: "Search here",
          labelClass: "text-bold",
          options: [],
          clearable: true,
          onChange: (data) => {
            this.onSearch();
          },
        },
        {
          name: "student_name",
          label: "Student Name",
          type: "text",
          defaultValue: " ",
          labelClass: "p-0",
        },
      ],
    };
  }

  trimObjectValues(obj) {
    const trimmedObj = {};
    for (const key in obj) {
      if (typeof obj[key] === "string") {
        trimmedObj[key] = obj[key].trim();
      } else {
        trimmedObj[key] = obj[key];
      }
    }
    return trimmedObj;
  }

  onSearch() {
    let data = this.refs.form.form.current.getData();
    // console.log("onSearch formData", data);
    data = this.trimObjectValues(data);
    data = Object.filter(data, (val) => val);
    // console.log("data on search", data);
    this.props.onSearch && this.props.onSearch(data);
  }

  clearTextData = () => {
    this.setState({
      fields: this.state.fields.map((field) => ({ ...field, value: "" })),
    });
    let data = this.refs.form.form.current.getData();
    data = Object.filter(data, (val) => "");
    // let sectionclear = document.getElementById("section_name");
    // if (sectionclear) {
    //   sectionclear.click();
    // }
    // let classclear = document.getElementById("class_name");
    // if (classclear) {
    //   classclear.click();
    //   document.getElementById("close_option")?.click();
    // }
    location.reload()
    this.props.onSearch && this.props.onSearch(data);
  };

  handleInputChange = (i, event) => {
    const newFields = [...this.state.fields];
    newFields[i].value = event.target.value;
    this.setState({ fields: newFields });
  };

  componentDidMount() {
    if (localStorage.getItem("currentAdminRole") === "SCHOOL_COORDINATOR") {
      this.getClassesBySchool(localStorage.getItem("school_id"));
    }
  }

  clearClassAndSection() {
    let sectionclear = document.getElementById("section_name");
    if (sectionclear) {
      sectionclear.click();
    }
    document.getElementById("close_option")?.click();
  }

  getClassesBySchool(school_id) {
    this.props.api
      .apiGetClassById({ school_id: school_id })
      .then(({ data }) => {
        let field = this.state.fields.find((o) => o.name === "class_name");
        field.options = data?.[0].records
          ?.map((item) => {
            return {
              ...item,
              class_name: item?.curriculum_grade,
            };
          })
          .options("class_name");
        this.setState({
          fields: this.state.fields,
          classes: data?.[0].records,
        });
      })
      .catch((error) => {});
  }

  getSectionsByClassId(classId) {
    if (classId) {
      let field = this.state.fields.find((o) => o.name === "section_name");
      let classes = this.state.classes.find((r) => r.class_id === classId);
      field.options = classes.section
        ?.map((item) => {
          return {
            ...item,
            section_name: item?.name,
          };
        })
        .options("name", "name");
      field.defaultValue = null;
      this.setState({
        ...this.state.fields,
        sections: classes.section,
      });
    }
  }

  render() {
    let { fields } = this.state;

    return (
      <>
            <AppForm className="row filter-row" ref="form">
        {fields.map((field, i) => (
          <div className="col-sm-6 col-md-3" key={i}>
            {/* <AppInputFocus {...field} onChange={event => this.handleInputChange(i, event)}></AppInputFocus> */}
            <AppInput {...field}></AppInput>
          </div>
        ))}
        <div className="col-sm-6 col-md-3 align-content-center">
          <button
            className="btn btn-success btn-block w-100"
            onClick={() => this.onSearch()}
          >
            Search
          </button>
        </div>
        <div className="clear-all">
          <span onClick={() => this.clearTextData()}>Clear Filter</span>
        </div>
      </AppForm>
      <button
          id="close_option"
          className="btn btn-sm btn-outline-secondary d-none"
        >
          <span>Hidden Button</span>
        </button>
      </>
      
    );
  }
}

export default connect(StudentSearch);
