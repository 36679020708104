import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "./header";
import "antd/dist/antd.min.css";

export class PublicLayout extends Component {
  render() {
    let props = this.props;
    return (
      <>
        <div className={`main-wrapper public`}>
          <Header />
          <div className="publicpage-wrapper">{this.props.children}</div>
        </div>
        {(props.store.requests || []).map((request, i) => (
          <AppModal
            active={!!request.message}
            footer={["alert", "confirm", "prompt"].includes(request.type)}
            key={i}
            {...request}
            onClose={() => mixins.popRequest(request.id)}
            onCancel={() => mixins.popRequest(request.id)}
          >
            <div className="min-w-[300px] text-center break-word">
              <i className="animate-spin pi pi-fw pi-spinner h-3 w-3"></i>
              <div>{request.message}</div>
            </div>
          </AppModal>
        ))}
      </>
    );
  }
}

export default connect(PublicLayout);
