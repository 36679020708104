class Emitter {
    constructor() {
        this.listners = {};
        this.nodes = {};
        this.nodecallbacks = {};
    }

    listners = {};
    nodes = {};
    nodecallbacks = {};

    remove(key) {
        delete this.listners[key];
    }

    setTrigger(key, node = null, callback = null) {
        this.nodes[key] = this.nodes[key] ? this.nodes[key] : [];
        if (node) {
            if (!this.nodes[key].includes(node)) {
                this.nodes[key].push(node);
            }
            if (callback) {
                node.triggercallbacks = node.triggercallbacks || {};
                node.triggercallbacks[key] = callback;
                this.nodecallbacks[key] = callback;
            }
        }
        console.log(`${key} Event Listner Added in ${node.componentName}`);
        //console.log("tochedNodes REg",key,this.nodes[key].length);
    }

    on(key, callback) {
        this.listners[key] = callback;
    }

    haslistner(key) {
        return !!this.listners[key];
    }

    trigger(key, ...arg) {
        let callback = this.listners[key] || (() => "");
        let tochedNodes = this.nodes[key] || [];
        console.log(`${key} Emmited`, ...arg);
        //console.log("tochedNodes",key,tochedNodes,tochedNodes.length);
        tochedNodes.forEach((node) => {
            let triggercallbacks = node.triggercallbacks || {};
            let nodecall = triggercallbacks[key] || this.nodecallbacks[key] || node[key];
            //console.log("nodecall",key,nodecall,triggercallbacks);
            if (nodecall instanceof Function) {
                console.log(`${key} Emmit Resolved`, {
                    key,
                    node: this.nodecallbacks[key],
                    trigger: triggercallbacks[key],
                    component: node.componentName,
                });
                nodecall.call(node, ...arg);
            }
        });
        let ret = callback(...arg);
        if (ret instanceof Promise) {
            return new Promise((resolve, reject) => {
                Promise.all([ret]).then(([val]) => resolve(val));
            });
        } else {
            return ret;
        }
    }
}

export default Emitter;
