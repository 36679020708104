import { Switch } from "antd";
import React from "react";
import { useRef, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom";

function AppAccordian(props) {
  let {
    data = [],
    id = "accordionExample",
    handleEdit,
    handleDelete,
    handleToggle,
    crudaction = true,
    toggleaction = true,
    statusaction = true,
    targetType = "download",
    currentSession,
  } = props;
  const accordian = useRef();
  const [role, setRole] = useState("");
  useEffect(() => {
    setRole(localStorage.getItem("currentAdminRole"));
    if (!currentSession) {
      accordian?.current
        ?.querySelector(".accordion-item>.accordion-header>button")
        ?.click();
    }
  }, [data]);

  const options = {
    chart: {
      type: "donut",
    },
    colors: ["#FBA106", "#CACACA42"],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 180,
        options: {
          chart: {
            width: 150,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div ref={accordian} className="accordion custom_accordion" id={id}>
      {data.map(
        (
          {
            Content = () => <div></div>,
            Title = () => (
              <button className="btn btn-link" type="button">
                Collapsible Group Item #1
              </button>
            ),
            Status = () => <></>,
            Percent = () => <></>,
            Links = () => <></>,
          },
          i
        ) => (
          // if(data[i].record.session_id == currentSession){
          //   document.getElementById('accordianToggle').click();
          // }
          <div className="accordion-item" key={i}>
            <h2 className="accordion-header" id={"heading" + i}>
              <button
                className="accordion-button collapsed text-base"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={"#collapse" + i}
                aria-expanded="true"
                aria-controls={"collapse" + i}
              >
                <div className="button_content">
                  <div className="title">
                    <Title />
                  </div>
                  <div className="d-flex mx-4">
                    {role == "SUPER_ADMIN" && crudaction ? (
                      <div className="crud_box">
                        <button
                          type="button"
                          className="edit ms-2"
                          onClick={(...args) =>
                            handleEdit(...args, data[i]?.record)
                          }
                        >
                          <i class="fa fa-pencil-square-o"></i>
                        </button>
                        <button
                          type="button"
                          className="delete ms-2"
                          onClick={(...args) =>
                            handleDelete(...args, data[i]?.record)
                          }
                        >
                          <i class="fa fa-trash-o"></i>
                        </button>
                      </div>
                    ) : (
                      <div className="status_box">
                        {/* <div>
                          <div id="chart p-0" style={{ width: "15%" }}>
                            <ReactApexChart
                              options={options}
                              series={[
                                data[i]?.record?.progress,
                                100 - data[i]?.record?.progress,
                              ]}
                              type="donut"
                              height={68}
                            />
                          </div>
                          <div id="html-dist"></div>
                        </div>
                        {data[i]?.record?.progress?.toFixed(2)}% completed */}
                      </div>
                    )}
                  </div>
                </div>
              </button>
            </h2>
            <div
              id={"collapse" + i}
              className={`accordion-collapse collapse ${
                data[i].record.session_id == currentSession ? "show" : ""
              }`}
              aria-labelledby={"heading" + i}
              data-bs-parent={"#" + id}
            >
              <div className="accordion-body px-lg-5">
                <p className="content_text">
                  <Content />
                </p>
                <div className="link_box_container">
                  {targetType == "download" &&
                    Links() instanceof Array &&
                    Links().map((item, i) => {
                      return (
                        <a
                          href={`${item.attachment_id}`}
                          target="_blank"
                          className="link_box"
                        >
                          <img
                            src={
                              item.file_type === "video"
                                ? imagepaths.videoIcon
                                : item.file_type === "ppt"
                                ? imagepaths.pptIcon
                                : item.file_type === "doc"
                                ? imagepaths.docIcon
                                : imagepaths.notesIcon
                            }
                            alt="link icon"
                            className="me-2"
                          />
                          {item.file_name}
                        </a>
                      );
                    })}
                  {targetType == "route" &&
                    Links() instanceof Array &&
                    Links().map((item, i) => {
                      return (
                        <Link
                          to={`${baseroute}`}
                          className="link_box"
                          onClick={(e) => props.getlinktype(e, item.file_type)}
                        >
                          <img
                            src={
                              item.file_type === "video"
                                ? imagepaths.videoIcon
                                : item.file_type === "ppt"
                                ? imagepaths.pptIcon
                                : item.file_type === "doc"
                                ? imagepaths.docIcon
                                : imagepaths.notesIcon
                            }
                            alt="link icon"
                            className="me-2"
                          />
                          {item.file_name}
                        </Link>
                      );
                    })}

                  <Link
                    to={`/contentmanagement/sessionsview/module/videopage?grade=${data[i]?.sessiondetails?.grade}&level=${data[i]?.sessiondetails?.level}&type=${data[i]?.sessiondetails?.type}&curriculum_id=${data[i]?.sessiondetails.curriculum_id}?&session_id=${data[i]?.record?.session_id}`}
                    className="link_box"
                  >
                    <img
                      src={imagepaths.videoIcon}
                      alt="link icon"
                      className="me-2"
                    />
                    Video
                  </Link>
                  <Link
                    to={`/contentmanagement/sessionsview/module/pptpage?grade=${data[i]?.sessiondetails?.grade}&level=${data[i]?.sessiondetails?.level}&type=${data[i]?.sessiondetails?.type}&curriculum_id=${data[i]?.sessiondetails.curriculum_id}?&session_id=${data[i]?.record?.session_id}`}
                    className="link_box"
                  >
                    <img
                      src={imagepaths.pptIcon}
                      alt="link icon"
                      className="me-2"
                    />
                    Manual
                  </Link>
                  <Link
                    to={`/contentmanagement/sessionsview/module/handouts?grade=${data[i]?.sessiondetails?.grade}&level=${data[i]?.sessiondetails?.level}&type=${data[i]?.sessiondetails?.type}&curriculum_id=${data[i]?.sessiondetails.curriculum_id}?&session_id=${data[i]?.record?.session_id}`}
                    className="link_box"
                  >
                    <img
                      src={imagepaths.docIcon}
                      alt="link icon"
                      className="me-2"
                    />
                    Handouts
                  </Link>
                  <Link
                    to={`/contentmanagement/sessionsview/module/assessmentspage?grade=${data[i]?.sessiondetails?.grade}&level=${data[i]?.sessiondetails?.level}&type=${data[i]?.sessiondetails?.type}&curriculum_id=${data[i]?.sessiondetails.curriculum_id}?&session_id=${data[i]?.record?.session_id}`}
                    className="link_box"
                  >
                    <img
                      src={imagepaths.notesIcon}
                      alt="link icon"
                      className="me-2"
                    />
                    Assessments
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default AppAccordian;
