import React, { Component } from "react";
import menu from "./menu";
import profilemenu from "./profilemenu";

import "./layout.scss";
class Layout extends Component {
  constructor(props) {
    super(props);
    this.menu = menu;
    window.alert = this.props.mixin.alert;
    window.loader = this.props.mixin.loader;
    window.confirm = this.props.mixin.confirm;
    window.prompt = this.props.mixin.prompt;
    window.success = this.props.mixin.success;
    window.error = this.props.mixin.error;
  }
  render() {
    let props = this.props;
    return (
      <div
        className={Object.className({
          AppLayout: true,
          [props.store?.theme]: true,
        })}
      >
        {/* <AppNavbar className="fixed top-0 left-0 w-screen" links={this.menu} profilemenu={profilemenu} start={start} end={end} /> */}
        <div className="flex flex-col bg-slate-100 dark:bg-slate-800 dark:text-white landbg">
          <div></div>
          <div className="h-screen max-h-screen overflow-auto flex bg-black/63">
            {this.props.children}
            {/* <AppFooter/> */}
          </div>
        </div>
        {(props.store.requests || []).map((request, i) => (
          <AppModal
            active={!!request.message}
            footer={["alert", "confirm", "prompt"].includes(request.type)}
            key={i}
            {...request}
            onClose={() => mixins.popRequest(request.id)}
            onCancel={() => mixins.popRequest(request.id)}
          >
            <div className="min-w-[300px] text-center break-word">
              <i className="animate-spin pi pi-fw pi-spinner h-3 w-3"></i>
              <div>{request.message}</div>
            </div>
          </AppModal>
        ))}
      </div>
    );
  }
}

export default window.connect(Layout);
