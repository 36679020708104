import React, { Component } from "react";

export class EventConfigModal extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("EventConfigModal");
    this.form = React.createRef();
    const today = new Date().toISOString().split("T")[0];
    this.state = {
      startOccurDate: props?.recurrenceInfo?.start_occurance_date
        ? new Date(props.recurrenceInfo.start_occurance_date)
            .toISOString()
            .split("T")[0]
        : today,
      endOccurDate: props?.recurrenceInfo?.end_occurance_date
        ? new Date(props.recurrenceInfo.end_occurance_date)
            .toISOString()
            .split("T")[0]
        : "",
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.recurrenceInfo?.start_occurance_date !==
      this.props.recurrenceInfo?.start_occurance_date
    ) {
      this.setState({
        startOccurDate: this.props.recurrenceInfo.start_occurance_date
          ? new Date(this.props.recurrenceInfo.start_occurance_date)
              .toISOString()
              .split("T")[0]
          : new Date().toISOString().split("T")[0],
      });
    }
  }

  handleStartDateChange = (e) => {
    const selectedStartDate = e.target.value;
    this.setState({
      startOccurDate: selectedStartDate,
      endOccurDate: "",
    });
  };

  closemodal() {}

  render() {
    let { props } = this;
    let { id, title, submitText = "SUBMIT", onClose } = props;
    const { startOccurDate, endOccurDate } = this.state;
    // console.log(props.recurrenceInfo, startOccurDate, endOccurDate);

    return (
      <AppModalBs
        id={id}
        title={title}
        onClose={(...args) => this.closemodal(...args)}
      >
        <AppForm
          ref={this.form}
          onSubmit={(...args) => {
            const endOccurDateValue = args[2].querySelector(
              'input[name="end_occurance_date"]'
            ).value;

            if (!endOccurDateValue) {
              alert("Please select an 'End By' date.");
              return;
            }

            props?.onSubmit(...args);
          }}
        >
          <Row className="mx-0">
            <AppInput
              name="event_time"
              label="Event Time"
              // options={classnames}
              defaultValue={props?.recurrenceInfo?.event_time || ""}
              disabled={true}
              required
              // disabled={viewmode || editmode}
              className="col-12"
            ></AppInput>
            <div className="mt-3"></div>
            <h4>Recurrence Pattern *</h4>
            <div className="row m-1">
              {/* <div>
                <AppInput
                  type="radio"
                  name="recurrent_pattern"
                  value="Weekly"
                  className="col-12"
                ></AppInput>
              </div> */}
              {[
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
              ].map((day) => (
                <div className="col-3 d-flex align-items-center p-2">
                  <input
                    type="checkbox"
                    name="selected_days"
                    className="rec_check"
                    value={day}
                    // checked={this.state.sendAssessmentMail}
                    // disabled={readMode}
                    // onClick={(e) => this.handleCheckbox(e)}
                  />
                  <label className="ms-2 mt-0">{day}</label>
                </div>
              ))}
            </div>
            <hr className="mt-3" />
            <h4>Range of recurrence</h4>
            <div className="col-12 col-md-6">
              <label className="col-form-label">
                Start By<span className="text-danger">*</span>
              </label>
              <input
                type="date"
                name="start_occurance_date"
                className="form-control col-12 col-md-6"
                id="start_occurance_date"
                value={startOccurDate}
                onChange={this.handleStartDateChange}
                disabled={!props.editmode}
                required
              />
            </div>

            <div className="col-12 col-md-6">
              <label className="col-form-label">
                End By<span className="text-danger">*</span>
              </label>
              <input
                type="date"
                name="end_occurance_date"
                className="form-control col-12 col-md-6"
                id="end_occurance_date"
                value={endOccurDate}
                onChange={(e) =>
                  this.setState({ endOccurDate: e.target.value })
                }
                min={
                  new Date(new Date(startOccurDate).getTime() + 86400000)
                    .toISOString()
                    .split("T")[0]
                }
                required
              />
            </div>
          </Row>
          <div className="row no-gutters mt-2">
            <Row className="mt-4">
              <button
                type="reset"
                className="btn close-btn col col-md-1 ml-auto rounded-1 text-dark"
                data-bs-dismiss="modal"
                onClick={(...args) => this.closemodal(...args)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn save-btn col col-md-1 ml-auto ms-2 me-2 rounded-1"
              >
                Save
              </button>
            </Row>
          </div>
        </AppForm>
      </AppModalBs>
    );
  }
}

export default connect(EventConfigModal);
