import React, { Component } from 'react'

export class AppSteps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current_step:(this.props.steps[0]?.name||'')
        }
        window.cAppSteps = this;
    }
    isActive(o) {
        return this.state.current_step === o.name;
    }

    get activeIndex() {
        return this.props.steps.findIndex(o=>o.name==this.state.current_step);
    }

    get StepContent() {
        let index =  this.activeIndex;
        let EmptyElement = (()=>('No Step Content Found.?'));
        let activeObj = this.props.steps[index];
        let nextObj = this.props.steps[index+1];
        let Content = index>=0?(activeObj?.Content||EmptyElement):EmptyElement;
        let activeprops = activeObj?.props || {};
        let next = ()=>this.setState({current_step:(nextObj?.name||'')});
        let oldSubmit = activeprops?.onSubmit;
        activeprops.onSubmit = (e)=>{
            e.preventDefault();
            let submit = (oldSubmit||(()=>('')));
            submit(e,!!nextObj,next,nextObj);
        };
        return (props)=><Content index={index} {...props} {...activeprops} />
    }

    get eclass() {
        return {
            steplabel:{'block step rounded-full border px-4 py-2 mx-2 my-1 m-clip text-center cursor-pointer':true},
            stepbadge:{'step rounded-full border px-4 py-2 mx-1 first:!ml-auto last:!mr-0 text-center':true}
        }
    }

    componentDidMount() {
        
    }

    render() {
        let { StepContent,eclass, props } = this;
        return (
            <div>
                <div className='steps flex mb-4'>
                    {
                        props.steps.map((o,i)=>(
                            <div 
                                key={'step-'+i} 
                                className={Object.className({'activated':i<=this.activeIndex,...eclass.stepbadge})}
                                >
                                    {
                                        (i<=this.activeIndex)?<i className='fa fa-check'></i>:(i+1)
                                    }
                            </div>
                        ))
                    }
                </div>
                <div className='steps grid grid-auto mb-4'>
                    {
                        props.steps.map((o,i)=>(
                            <div 
                                key={'step-'+i} 
                                className={Object.className({'activated':this.isActive(o),...eclass.steplabel})}
                                onClick={()=>this.setState({current_step:o.name})}
                                >
                                {o.label}
                            </div>
                        ))
                    }
                </div>
                <div className='step-content'>
                    <StepContent />
                </div>
            </div>
        )
    }
}

export default connect(AppSteps);