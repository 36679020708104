import React, { Component } from "react";
import _enum from "src/mixins/enum";
export class TaskSearch extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.handleRangeChange = this.handleRangeChange.bind(this);

    this.state = {
      loader: false,
      fields: [
        {
          type: "select",
          name: "column",
          label: "Search",
          placeholder: "Search by Column Names",
          labelClass: "text-bold",
          options: [
            { label: "School Name", key: "assigned_schools" },
            { label: "Priority", key: "priority" },
            { label: "Task Status", key: "task_status" },
            { label: "Due In", key: "dueIn" },
          ],
          onChange: (data) => {
            this.handleColumnChange(data);
          },
        },
        {
          name: "val",
          labelClass: "text-bold",
          label: "Values",
          type: "select",
          options: [],
          placeholder: "Enter the values",
        },
      ],
    };
  }

  handleColumnChange(data) {
    let field = this.state.fields.find((o) => o.name === "val");
    field.multi = false;
    if (data[0]?.key === "priority") {
      field.options = _enum.PRIORITY;
    } else if (data[0]?.key === "task_status") {
      field.options = _enum.TASK_STATUS;
    } else if (data[0]?.key === "assigned_schools") {
      field.options = this.props?.schoolsData || [];
      field.multi = true;
    } else if (data[0]?.key === "dueIn") {
      field.options = _enum.DATE_FILTER;
    }
    
    if (data[0]?.key === "task_name") {
      field.type = "text";
    }else{
      field.type = "select";
    }
    this.setState({
      fields: this.state.fields,
    });
  }

  handleRangeChange(e) {
    let data = _enum.DATE_FILTER.find(
      (filter) => filter.label === e.target.value
    )?.key;
    this.props.onSearch && this.props.onSearch({ dueIn: data });
  }

  trimObjectValues(obj) {
    const trimmedObj = {};
    for (const key in obj) {
      if (typeof obj[key] === "string") {
        trimmedObj[key] = obj[key].trim();
      } else {
        trimmedObj[key] = obj[key];
      }
    }
    return trimmedObj;
  }

  onSearch() {
    let data = this.refs.form.form.current.getData();
    let result = {};
    result[data.column] = data.val;

    result = this.trimObjectValues(result);
    result = Object.filter(result, (val) => val);
    if (data.column === "assigned_schools") {
      result[data.column] = data.val?.splitBy(",");
    }
    console.log("data on search", result);
    this.props.onSearch && this.props.onSearch(result);
  }

  handleInputChange = (i, event) => {
    const newFields = [...this.state.fields];
    if (newFields[i].name != "status") newFields[i].value = event.target.value;
    this.setState({ fields: newFields });
  };

  componentDidMount() {
    let columnField = this.state.fields.find(
      (field) => field.name == "column"
    );
    columnField.options = [
      { label: "Task Name", key: "task_name" },
      ...columnField.options
    ]
    if (localStorage.getItem("currentAdminRole") === "SCHOOL_COORDINATOR") {
      columnField.options = [
        { label: "Task Name", key: "task_name" },
        { label: "Priority", key: "priority" },
        { label: "Task Status", key: "task_status" },
        { label: "Due In", key: "dueIn" },
      ];
    }
    this.setState({
      fields: this.state.fields,
    });
  }

  clearTextData = () => {
    this.setState({
      loader: true,
    });
    location.reload();
    // this.setState({
    //   fields: this.state.fields.map((field) => ({ ...field, value: "" })),
    // });
    // let data = this.refs.form.form.current.getData();
    // this.refs.form.form.current.reset();
    // data = Object.filter(data, (val) => "");
    // this.props.onSearch && this.props.onSearch(data);
  };
  render() {
    let { fields, loader } = this.state;

    return (
      <div className="card bg-white p-3">
        {loader && (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        )}
        <AppForm className="row filter-row" ref="form">
          {fields.map((field, i) => (
            <div className="col-sm-6 col-md-4" key={i}>
              <AppInput
                {...field}
                // onChange={(event) => this.handleInputChange(i, event)}
              ></AppInput>
            </div>
          ))}
          <div className="col-sm-6 col-md-2 m-auto">
            <button
              className="btn btn-outline-secondary btn-block w-100"
              type="reset"
              onClick={() => this.clearTextData()}
            >
              Clear
            </button>
          </div>
          <div className="col-sm-6 col-md-2 m-auto">
            <button
              type="submit"
              className="btn btn-success btn-block w-100"
              onClick={() => this.onSearch()}
            >
              Search
            </button>
          </div>
        </AppForm>
        {/* <hr />
         <div>Due In</div>
        <AppForm className="row filter-row" ref="form1">
          {_enum.DATE_FILTER.map((field, i) => (
            <div className="col-sm-6 col-md-auto" key={field.key}>
              <AppInputFocus
                type="radio"
                name="dueIn"
                defaultChecked={field.checked || false}
                value={field.label}
                onChange={this.handleRangeChange}
              ></AppInputFocus>
            </div>
          ))}
        </AppForm> */}
      </div>
    );
  }
}

export default connect(TaskSearch);
