import React, { Component } from "react";

export class GoogleTranslator extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("GoogleTranslator");
  }

  getLanguageOptions() {
    return [
      ...document.querySelectorAll("#google_translate_element select option"),
    ];
  }

  componentDidMount() {
    document.body.addEventListener("googleLangugeLoaded", (e) => {
      console.log("googleLangugeLoaded");
      this.checkTranslateLanguage();
    });
    document.body.addEventListener("googleLangugeTaped", (e) => {
      console.log("googleLangugeTaped");
      this.checkTranslate();
    });
    document.body.addEventListener("googleLangugeChanged", (e) => {
      console.log("googleLangugeChanged");
      this.checkTranslate();
    });
  }

  checkTranslateLanguage() {
    console.log("checkTranslateLanguage", window.translateLanguage);
    if (window.translateLanguage) {
      window.translateLanguage();
    } else {
      setTimeout(() => this.checkTranslateLanguage(), 500);
    }
  }
  checkTranslate() {
    let options = this.getLanguageOptions();
    // console.log("checkTranslate", options);
    if (options.length) {
      this.getOptions();
    } else {
      setTimeout(() => this.checkTranslate(), 500);
    }
  }

  getOptions() {
    let select = document.querySelector("#google_translate_element select");
    let options = this.getLanguageOptions();
    let translateLang = this.props.store.translateLang.concat(["en", ""]);
    // console.log({ alloptions: options });
    options.map((option) => {
      if (!(translateLang || []).includes(option.value)) {
        // console.log("option", option.value);
        option.remove();
      }
    });
    // console.log({ options });
    let newoptions = this.getLanguageOptions();
    if (!newoptions.length) {
      select.innerHTML = "";
      translateLang
        .filter((v) => v)
        .unique()
        .map((key) => {
          let defaultOption = document.createElement("option");
          let data = _enum.APPLICANT_LANGUAGE_LIST.find((o) => o.key == key);
          defaultOption.setAttribute("value", data.key);
          defaultOption.innerHTML = data.label;
          select.appendChild(defaultOption);
        });
    }
  }

  render() {
    return <div id="google_translate_element"></div>;
  }
}

export default connect(GoogleTranslator);
