import { Component } from "react";
import { ToastContainer } from "react-toastify";
import LayoutWrapper from "./LayoutWrapper";
import PasswordResetModal from "./passwordResetModal";
import StudentManagement from "./StudentManagement";
import TableManagement from "./TableManagement";

export class ClassManagement extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("ClassManagement");
    this.state = {
      data: [],
      rowcount: 0,
      search: {
        limit: 10,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
      editData: {},
      columns: [],
      currentView: "student",
      // classArr: ["VI", "VII", "VIII", "IX"],
      currentClass: "VI",
      popupfields: [],
      popupdata: {},
      editMode: false,
      sections: [],
      loader: false,
      classId: "",
      openResetPwdModal: false,
      studentCredentials: {
        user_id: "",
        otp: "",
      },
    };
  }

  componentDidMount() {}
  // DATA FETCHING END

  downloadFile() {}

  onNext() {}

  onPrev() {}

  onPageChange(pageData) {
    if (this.state.currentView == "student") {
      this.setState(
        {
          search: {
            ...this.state.search,
            page: pageData.current,
            limit: pageData.pageSize || pageData.defaultPageSize || 10,
          },
        },
        () => this.fetchStudentData()
      );
    } else {
      this.setState(
        {
          search: {
            ...this.state.search,
            page: pageData.current,
            limit: pageData.pageSize || pageData.defaultPageSize || 10,
          },
        },
        () => this.fetchTableData()
      );
    }
  }

  handleTabChange(e) {
    console.log(e);
    if (e == "student") {
      this.setState({
        columns: this.classColumns,
        currentView: "student",
        popupfields: this.state.studentFields,
        search: {
          ...this.state.search,
          page: 1,
          limit: 10,
        },
      });
    } else {
      this.setState({
        columns: this.tableColumns,
        currentView: "table",
        popupfields: this.state.tableFields,
        search: {
          ...this.state.search,
          page: 1,
          limit: 10,
        },
      });
    }
  }

  render() {
    let {
      currentView,
      loader,
    } = this.state;

    // console.log("this is data ", data)
    return (
      <>
        <ToastContainer position="top-right" />
        {loader && (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        )}
        <LayoutWrapper title="Class Management" back="Class Management">
          <div className="row">
            <div className="col-md-12">
              <button
                className={`btn rounded-full border mx-1 min-w-[150px] m-clip ${
                  currentView == "student" ? "tab-active" : ""
                }`}
                onClick={() => this.handleTabChange("student")}
              >
                Student
              </button>
              <button
                className={`btn rounded-full border mx-1 min-w-[150px] m-clip ${
                  currentView == "table" ? "tab-active" : ""
                }`}
                onClick={() => this.handleTabChange("table")}
              >
                Table
              </button>
            </div>
          </div>
          {currentView == "student" ? (
            <StudentManagement />
          ) : (
            <TableManagement />
          )}
        </LayoutWrapper>

        {this.state.openResetPwdModal && (
          <PasswordResetModal
            userId={this.state.studentCredentials.user_id}
            otp={this.state.studentCredentials.otp}
            onClose={() => {
              this.setState({
                openResetPwdModal: false,
              });
            }}
          />
        )}
      </>
    );
  }
}

export default connect(ClassManagement);
