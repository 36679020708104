// DayAttendanceCell.js
import React from "react";
import { toast } from "react-toastify";

const DayAttendanceCell = ({ text, record, dateobj, status, fetchList, api }) => {
  const handleClick = () => {
    if (["present", "absent"].includes(text.status)) {
      confirm(
        <div>
          Are you sure you want to mark the status of this student as{" "}
          <span className="text-orange-500">
            {text.status === "absent" ? "present" : "absent"}
          </span>
          ?
        </div>,
        {
          title: (
            <div>
              <em className="la la-exclamation-circle text-orange-500 bounce"></em>
            </div>
          ),
          extra: true,
          okText: "Yes",
          cancelText: "No",
        }
      ).promise.then(() => {
        api
          .apiUpdateAttendanceHistory({ attendance_id: text?.attendance_id }, {
            attendance_status: text?.status === "absent" ? "PRESENT" : "ABSENT",
          })
          .then((response) => {
            toast.success("Attendance updated successfully");
            fetchList();
          })
          .catch((error) => {
            toast.error("Error updating attendance");
          });
      });
    }
  };

  return (
    <div className="text-center" title="" onClick={handleClick}>
      {status[text?.status] || status["nostatus"]}
    </div>
  );
};

export default DayAttendanceCell;
