import statecity from "../assets/json/statecity";
window.statecity = statecity;
export const state = {
    language:"english",
    config: {
        name: "",
        shortname: "",
        tagline: "",
        logo: "self",
        colors: {
            "defaultbg": "white",
            "defaultText": "black",
            "primary": "#1c67f6",
            "primaryText": "white",
            "secoundary": "#692E20",
            "secoundaryText": "white",
        }
    },
    loading: false,
    requests: [],
    theme: "",
    auth: "",
    user: null,
    leaveTypes: ['casual_leaves', 'sick_leaves', 'comp_off'].options()
}

export default state;