import React from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import "./bootstrap";

import * as bootstrap from 'bootstrap';

import mixins from "./mixins";
mixins.setInitials();
import router from "./routes";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import "./components/partials";


window.mixins = mixins;
window.Modal = bootstrap.Modal;
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<RouterProvider router={router} />
		</PersistGate>
	</Provider>
);

setTimeout(() => {
	Object.entries(imagepaths).map(([key, val]) => {
		document.body.style.setProperty(`--Image-${key.snakeCase()}`, `url(${val})`);
	});
});